import React, { useContext, useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../utils/firebase/firebase.utils";
import { UserDataContext } from "../../context/userData.context";
import { ClipLoader } from "react-spinners";

const EmailVerified = ({ email, setemail, cfw }) => {
  const [loading, setLoading] = useState(false);
  const {
    wishlist,
    setWishlist,
    address,
    setAddress,
    alert,
    setAlert,
    slide,
    setslide,
    currentlySelectedAddress,
    setCurrentlySelectedAddress,
  } = useContext(UserDataContext);

  const resetPassword = async (email) => {
    setLoading(true);
    try {
      await sendPasswordResetEmail(auth, email);
      setAlert({
        color: "blue",
        text: "Kindly check your email",
      });
      setslide(true);
      // route page
    } catch (err) {
      setAlert({
        color: "red",
        text: "Sorry please enter a correct email or password has already been reset",
      });
      setslide(true);
      console.log(
        "Sorry please enter a correct email or password has already been reset"
      );
    }
    setLoading(false);
  };

  return (
    <div className="box">
      <h1 className=" font-bold">Forgot Password</h1>
      <p>
        You can now easily access your account, view your order history, track
        your orders, and much more.
      </p>

      <div class="ps">
        <div class="pc">
          <div class="label_and_passwordView">
            <label
              for="email"
              className="password_label text-gray-300 "
              style={{
                color: "#424242",
                marginBottom: ".5rem",
              }}
            >
              Email Address
            </label>

            <div class="visibility">
              <img src="images/invisible.png" alt="" id="visibility" />
            </div>
          </div>

          <input
            type="email"
            name="password"
            class="password_input"
            id="passwordValue"
            onChange={(e) => setemail(e.target.value)}
            value={email}
            required
          />
        </div>
      </div>

      <button
        class="sign_up"
        disabled={loading}
        onClick={() => {
          if (email.length > 0) {
            resetPassword(email);
            cfw();
          } else {
            setAlert({
              color: "yellow",
              text: "Input your email",
            });
            setslide(true);
          }
        }}
      >
        {loading ? <ClipLoader size={20} color="white" /> : "Next"}
      </button>
    </div>
  );
};

export default EmailVerified;
