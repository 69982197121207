import React, { useContext, useEffect, useRef, useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { CartContext } from "../../context/cart.context";
import { UserDataContext } from "../../context/userData.context";
import { UserContext } from "../../context/user.context";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../utils/firebase/firebase.utils";

const defaultFormFields = {
  displayName: "",
  phone: "",
  email: "",
  street: "",
  busStop: "",
  state: "",
};

const checkIfLagos = (state) => {
  let rearangeState = state.toLowerCase("");
  let lagos = "lagos";

  if (rearangeState.includes(lagos)) {
    return true;
  } else {
    return false;
  }
};

const CheckoutNewAddress = ({ clickForward }) => {
  const [value, setValue] = useState("");
  const [checkEmptyFeilds, setEmptyFeilds] = useState(true);
  const { cartItems, cartCount, cartTotal, shippingCost, setShippingCost } =
    useContext(CartContext);
  const { userData, setUserData, currentUser } = useContext(UserContext);
  const [lagosRegion, setLagosRegion] = useState([false, false]);
  const [isLagos, setIsLagos] = useState(false);

  // save in local storage
  const {
    wishlist,
    setWishlist,
    address,
    setAddress,
    alert,
    setAlert,
    slide,
    setslide,
    currentlySelectedAddress,
    setCurrentlySelectedAddress,
  } = useContext(UserDataContext);

  const [formFields, setFormFields] = useState(() =>
    currentlySelectedAddress ? currentlySelectedAddress : defaultFormFields
  );
  const { displayName, phone, email, street, busStop, state } = formFields;
  const ref = useRef();

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormFields({ ...formFields, [name]: value });
  };

  useEffect(() => {
    const res = checkIfLagos(state);
    setIsLagos(res);
  }, [state]);

  const [currentDate, setCurrentDate] = useState(new Date());
  const [nextCurrentDate, setNextCurrentDate] = useState(new Date());

  // Function to set the date four days ahead
  const setDateFourDaysAhead = () => {
    const newDate = new Date(currentDate);
    const nextNewDate = new Date(nextCurrentDate);
    newDate.setDate(newDate.getDate() + 2);
    nextNewDate.setDate(nextNewDate.getDate() + 4);
    setCurrentDate(newDate);
    setNextCurrentDate(nextNewDate);
  };

  useEffect(() => {
    setDateFourDaysAhead();
  }, []);

  return (
    <>
      <div class="one">
        <div class="menu">
          <span className="active">Address</span>
          <span>{">"}</span>
          <span>Confirmation</span>
        </div>

        <div className="inputs">
          <div class="es">
            <div class="eli">
              <label for="displayName" class="el">
                Full Name
              </label>

              <input
                type="text"
                class="ei"
                required
                onChange={handleChange}
                name="displayName"
                value={displayName}
              />
            </div>
          </div>

          <div class="ps">
            <div class="pc">
              <label for="phone" class="pl">
                Phone number
              </label>

              <div class="code_selector">
                <PhoneInput
                  international
                  defaultCountry="NG"
                  name="phone"
                  value={value}
                  onChange={setValue}
                />
              </div>
            </div>
          </div>

          <div class="es">
            <div class="eli">
              <label for="email" class="el">
                Email address
              </label>
              <input
                type="text"
                class="ei"
                required
                name="email"
                onChange={handleChange}
                value={email}
              />
            </div>
          </div>

          <div class="es">
            <div class="eli">
              <label for="street" class="el">
                Street address
              </label>
              <input
                type="text"
                class="ei"
                required
                name="street"
                onChange={handleChange}
                value={street}
              />
            </div>
          </div>

          <div class="fls">
            <div class="fc">
              <label for="busStop" class="fl">
                Nearest Bus-Stop
              </label>
              <input
                type="text"
                class="firstName_input"
                required
                name="busStop"
                onChange={handleChange}
                value={busStop}
              />
            </div>

            <div class="lc">
              <label for="lastName" class="ll">
                State
              </label>
              <input
                type="text"
                class="lastName_input"
                required
                name="state"
                onChange={handleChange}
                value={state}
              />
            </div>
          </div>

          <div class="ts">
            <div class="tc">
              {isLagos && (
                <div class="terms_box">
                  <div class="tc">
                    <input
                      type="checkbox"
                      name="terms"
                      checked={lagosRegion[0]}
                      class="terms_check1"
                      onClick={() => {
                        setLagosRegion([true, false]);
                      }}
                    />
                  </div>

                  <div class="tl">
                    <label for="terms">Lagos Island</label>
                  </div>

                  <div class="tc">
                    <input
                      type="checkbox"
                      name="terms"
                      class="terms_check1"
                      checked={lagosRegion[1]}
                      onClick={() => {
                        setLagosRegion([false, true]);
                      }}
                    />
                  </div>

                  <div class="tl">
                    <label for="terms">Lagos Mainland</label>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div class="two">
        <div className="f">
          <h1>Order Summary</h1>
          <div className="main">
            <div className="op">
              <span>Price</span>
              <span>₦ {cartTotal}</span>
            </div>

            <div className="op">
              <span>Discount</span>
              <span>₦0</span>
            </div>

            <div className="op">
              <span>Shipping</span>
            </div>

            <hr />

            <div className="op dark">
              <span>Total</span>
              <span>₦ {cartTotal}</span>
            </div>

            <div className="op ">
              <span>
                Estimated Delivery <span> {currentDate.toDateString()}</span> -
                <span> {nextCurrentDate.toDateString()}</span>
              </span>
            </div>

            <button
              className="btn-sec"
              onClick={() => {
                if (
                  displayName.length === 0 ||
                  email.length === 0 ||
                  street.length === 0 ||
                  busStop.length === 0 ||
                  state.length === 0
                ) {
                } else {
                  // add address

                  if (isLagos) {
                    // show prompt for island or mainland
                    if (lagosRegion[0] === false && lagosRegion[1] === false) {
                      setAlert({
                        color: "yellow",
                        text: "Kindly Select Island or Mainland",
                      });
                      setslide(true);
                      return "";
                    } else {
                      // set shipping cost
                      // islans
                      let isLarge = false;
                      cartItems.forEach((item) => {
                        if (
                          item?.Category === "Category" ||
                          item?.Name?.split("")?.includes("LOUNGE") ||
                          item?.Name?.split("")?.includes("Sofa")
                        ) {
                          isLarge = true;
                        }
                      });

                      if (lagosRegion[0] === true && cartCount > 1) {
                        setShippingCost(10000);
                      } else if (lagosRegion[0] === true && cartCount === 1) {
                        setShippingCost(7000);
                        {
                          /**mainland */
                        }
                      } else if (
                        lagosRegion[1] === true &&
                        cartCount === 1 &&
                        isLarge
                      ) {
                        setShippingCost(5000);
                      } else if (
                        lagosRegion[1] === true &&
                        cartCount === 1 &&
                        !isLarge
                      ) {
                        setShippingCost(3000);
                      } else if (lagosRegion[1] === true && cartCount > 1) {
                        setShippingCost(7000);
                      }
                    }
                  } else {
                    setShippingCost(0);
                  }

                  localStorage.setItem(
                    "address",
                    JSON.stringify([
                      ...address,
                      {
                        ...formFields,
                        phone: value,
                        isLagos,
                        isIsland: lagosRegion[0],
                        isMainland: lagosRegion[1],
                      },
                    ])
                  );

                  setAddress([
                    {
                      ...formFields,
                      phone: value,
                      isLagos,
                      isIsland: lagosRegion[0],
                      isMainland: lagosRegion[1],
                    },
                    ...address,
                  ]);
                  setCurrentlySelectedAddress({
                    ...formFields,
                    phone: value,
                    isLagos,
                    isIsland: lagosRegion[0],
                    isMainland: lagosRegion[1],
                  });
                  setAlert({
                    color: "green",
                    text: "New address added",
                  });

                  const addAddress = async () => {
                    const userRef = doc(db, "users", currentUser.uid);

                    await updateDoc(userRef, {
                      addresses: [
                        {
                          ...formFields,
                          phone: value,
                          isLagos,
                          isIsland: lagosRegion[0],
                          isMainland: lagosRegion[1],
                        },
                        ...address,
                      ],
                    });
                  };

                  if (currentUser) {
                    addAddress();
                  }

                  setslide(true);

                  clickForward();
                }
              }}
              style={{
                backgroundColor: `${
                  displayName?.length === 0 ||
                  email?.length === 0 ||
                  street?.length === 0 ||
                  busStop?.length === 0 ||
                  state?.length === 0
                    ? "#424242"
                    : "#84c029"
                }`,
              }}
              ref={ref}
            >
              Confirmation
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckoutNewAddress;
