import React, { useContext, useState } from "react";
import NoAddress from "./NoAddress";
import { UserDataContext } from "../../context/userData.context";
import SavedAdderessComponent from "../checkout/saved address/savedAddress.component";

const Address = ({ AddAddress }) => {
  const { address, setAddress } = useContext(UserDataContext);
  const [selectedOption, setSelectedOption] = useState(0);

  if (address.length === 0) {
    return <NoAddress AddAddress={() => AddAddress()} />;
  } else {
    return (
      <>
        <div className="inputs">
          {address?.map((add, i) => {
            return (
              <SavedAdderessComponent
                add={add}
                i={i}
                setRadio={() => setSelectedOption(i)}
                radio={selectedOption}
                clickForward={() => AddAddress()}
              />
            );
          })}

          <p onClick={() => AddAddress()}>+ Add New Address</p>
        </div>
      </>
    );
  }
};

export default Address;
