import React, { useContext, useEffect, useState } from "react";
import { CartContext } from "../../context/cart.context";
import { useNavigate } from "react-router-dom";
import { UserDataContext } from "../../context/userData.context";

const ProductConfirmed = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [nextCurrentDate, setNextCurrentDate] = useState(new Date());
  const { cartItems, cartCount, cartTotal } = useContext(CartContext);

  // Function to set the date four days ahead
  const setDateFourDaysAhead = () => {
    const newDate = new Date(currentDate);
    const nextNewDate = new Date(nextCurrentDate);
    newDate.setDate(newDate.getDate() + 2);
    nextNewDate.setDate(newDate.getDate() + 2);
    setCurrentDate(newDate);
    setNextCurrentDate(nextNewDate);
  };

  useEffect(() => {
    setDateFourDaysAhead();
  }, []);

  const navigate = useNavigate();
  const { page, setPage } = useContext(UserDataContext);
  return (
    <>
      <div class="one confirm">
        <div className="interior_design ">
          <div className="a">
            <span>Confirmed</span>
            <h1>
              We Have Received <br />
              Your Order
            </h1>
            <hr />
            <p>
              Your order is confirmed and being processed. We'll send you an
              email shortly with your order details and shipping information. If
              you have any questions about your order, please contact us at
              lashinteriors20@gmail.com.
            </p>
          </div>
        </div>
      </div>
      <div class="two">
        <div className="f">
          <h1>Order Summary</h1>
          <div className="main">
            <div className="op">
              <span>Price</span>
              <span>₦ {cartTotal}</span>
            </div>

            <div className="op">
              <span>Discount</span>
              <span>₦0</span>
            </div>

            <div className="op">
              <span>Shipping</span>
              <span className="shp">Free</span>
            </div>

            <hr />

            <div className="op dark">
              <span>Total</span>
              <span>₦ {cartTotal}</span>
            </div>

            <div className="op ">
              <span>
                Estimated Delivery <span> {currentDate.toDateString()}</span> -
                <span> {nextCurrentDate.toDateString()}</span>
              </span>
            </div>

            <button
              className="btn-sec"
              onClick={() => {
                navigate("/dashboard");
                setPage(3);
              }}
            >
              View Order
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductConfirmed;
