import React, { useContext, useEffect, useState } from "react";
import { CartContext } from "../../context/cart.context";
import { UserDataContext } from "../../context/userData.context";
import { PaystackButton } from "react-paystack";
import { UserContext } from "../../context/user.context";
import { useNavigate } from "react-router-dom";
import { db } from "../../utils/firebase/firebase.utils";
import { Timestamp, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

const storePaystackDataInFirebase = async (user, pRefData, reference) => {
  const userRef = doc(db, "users", user?.uid);
  let res = [pRefData];
  // fetch data
  const docSnap = await getDoc(userRef);

  if (docSnap.exists()) {
    const prev = docSnap.data();

    if (prev?.paymentReference) {
      res = [pRefData, ...prev.paymentReference];
    }
  }

  await updateDoc(userRef, {
    paymentReference: [...res],
  });

  // also store payment reference data
  const paymentDocRef = doc(db, "orderList", reference);
  try {
    await setDoc(paymentDocRef, {
      ...pRefData,
    });
  } catch (err) {
    console.log(err, "error message");
  }
};

const Confirmation = ({ clickForward, clickBack }) => {
  // pk_test_40e7a53481e555c6aa73fd07ee76f1858320f9c3 test
  // pk_live_527cf8ccc847850f759c4bcc28d13eda239d6637 live
  const publicKey = "pk_live_527cf8ccc847850f759c4bcc28d13eda239d6637";

  const { cartItems, cartCount, cartTotal, shippingCost, setShippingCost } =
    useContext(CartContext);
  const {
    wishlist,
    setWishlist,
    address,
    setAddress,
    alert,
    setAlert,
    slide,
    setslide,
    currentlySelectedAddress,
    setCurrentlySelectedAddress,

    usersSpecialRequest,
    setUsersSpecialRequest,
  } = useContext(UserDataContext);

  const { currentUser } = useContext(UserContext);

  const { displayName, phone, email, street, busStop, state } =
    currentlySelectedAddress;

  const name = displayName;

  const [currentDate, setCurrentDate] = useState(new Date());
  const [nextCurrentDate, setNextCurrentDate] = useState(new Date());

  // Function to set the date four days ahead
  const setDateFourDaysAhead = () => {
    const newDate = new Date(currentDate);
    const nextNewDate = new Date(nextCurrentDate);
    newDate.setDate(newDate.getDate() + 2);
    nextNewDate.setDate(nextNewDate.getDate() + 4);
    setCurrentDate(newDate);
    setNextCurrentDate(nextNewDate);
  };

  const [amount, setAmount] = useState((cartTotal + shippingCost) * 100);

  useEffect(() => {
    setDateFourDaysAhead();

    setAmount(cartTotal * 100);
  }, [cartTotal]);

  const componentProps = {
    email,
    amount: (cartTotal + shippingCost) * 100,
    metadata: {
      name,
      phone,
    },
    publicKey,
    text: "continue to payment",
    onSuccess: ({ reference }) => {
      storePaystackDataInFirebase(
        currentUser,
        {
          cartItems: cartItems,
          TotalAmount: cartTotal + shippingCost,
          cartCount: cartCount,
          usersAddress: currentlySelectedAddress,
          time: Timestamp.now(),
          pReference: reference,
          specialRequest: usersSpecialRequest,
        },
        reference
      );
      setAlert({
        color: "green",
        text: `Your purchase was successful! Transaction reference: ${reference}`,
      });

      setslide(true);

      clickForward();
    },

    onClose: () => {
      setAlert({
        color: "yellow",
        text: "You closed your order",
      });

      setslide(true);
    },
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
    } else {
      setAlert({
        color: "yellow",
        text: "Kindly sign in before you can make a purchase",
      });

      setslide(true);
      navigate("/signin");
    }
  }, [currentUser]);

  return (
    <>
      <div class="one confirm">
        <div class="menu">
          <span>Address</span>
          <span>{">"}</span>
          <span className="active">Confirmation</span>
        </div>

        <div className="shp">
          <h1>Shipping Address</h1>
          <p
            onClick={() => {
              clickBack();
            }}
            style={{
              cursor: "pointer",
            }}
          >
            Edit
          </p>
        </div>

        <div className="cnt">
          <div>
            <h3>Full Name</h3>
            <p>{displayName || "-"}</p>
          </div>

          <div>
            <h3>E-Mail Address</h3>
            <p>{email || "-"}</p>
          </div>
        </div>

        <div className="cnt">
          <div>
            <h3>Street Address</h3>
            <p>{street || "-"}</p>
          </div>
          <div>
            <h3>Phone Number</h3>
            <p>{phone || "-"}</p>
          </div>
        </div>

        <div className="cnt">
          <div>
            <h3>Nearest Bus-Stop</h3>
            <p>{busStop || "-"}</p>
          </div>

          <div>
            <h3>State</h3>
            <p>{state || "-"}</p>
          </div>
        </div>
      </div>
      <div class="two">
        <div className="f">
          <h1>Order Summary</h1>
          <div className="main">
            <div className="op">
              <span>Price</span>
              <span>₦ {cartTotal}</span>
            </div>

            <div className="op">
              <span>Discount</span>
              <span>₦0</span>
            </div>

            <div className="op">
              <span>Shipping</span>
              <span className="shp">
                {shippingCost > 0 ? "₦ " + shippingCost : ""}
              </span>
            </div>

            <hr />

            <div className="op dark">
              <span>Total</span>
              <span>₦ {cartTotal + shippingCost}</span>
            </div>

            <div className="op ">
              <span>
                Estimated Delivery <span> {currentDate.toDateString()}</span> -
                <span> {nextCurrentDate.toDateString()}</span>
              </span>
            </div>

            <PaystackButton
              className="paystack-button btn-sec"
              {...componentProps}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Confirmation;
