import React, { useContext } from "react";
import WsChair from "../../assets/Dashboard/WishlistChair.png";
import Btn from "../../assets/Dashboard/cartIcon.png";
import { UserDataContext } from "../../context/userData.context";
import { useNavigate } from "react-router-dom";

const WishList = () => {
  const { wishlist, setWishlist } = useContext(UserDataContext);
  const navigate = useNavigate();
  console.log(wishlist)
  return (
    <div className="wishlistContainer">
      {wishlist.map((item, idx) => {
        return (
          <div className="ech" key={idx}>
            <img
              src={`http://u596h.erprev.com/res/img/items/${item?.ThumbImage}`}
              alt=""
            />

            <div>
              <h1>{item?.Name}</h1>

              <p>
                Upgrade your space <br /> with our products.
              </p>

              <span>₦ - -</span>

              <div
                className="btnw"
                onClick={() =>
                  navigate(
                    `/product/${item?.Name?.toLowerCase("")
                      ?.split(" ")
                      ?.join("")}`
                  )
                }
                style={{
                  cursor: "pointer"
                }}
              >
                <p>ADD</p>

                <img src={Btn} alt="" />
              </div>
            </div>
          </div>
        );
      })}
     
    </div>
  );
};

export default WishList;
