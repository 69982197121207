import React, {useEffect} from "react";
import img from "../assets/blog/blog.png";
import { useNavigate } from "react-router-dom";
import p1 from "../assets/blog/blogone.png";
import p3 from "../assets/blog/blogtwo.png";
import p2 from "../assets/blog/blogthree.png";
import ab6 from "../assets/blog/blogUser.png";

const Blog = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);
  return (
    <div className="blog">
      <div className="home">
        <div className="content">
          <h1>Insights and Inspiration</h1>
          <p>
            {" "}
            Explore our collection of articles and resources that offer insights
            and inspiration on design, decor, and lifestyle. From expert tips to
            inspiring stories, discover everything you need to elevate your
            space and enhance your life.
          </p>
        </div>

        <div className="img" style={{ cursor: "pointer" }}>
          <img src={img} alt="" />

          <div className="lower" onClick={() => navigate("/blogpost/1")}>
            <h3>Interior Design</h3>
            <h1>
              The Benefits of Natural Materials in Home Decor: Why You Should{" "}
              <span className="show">...</span>
              <span className="hide">
                Choose Wood, Stone, and Other Natural Materials for Your Home
              </span>
            </h1>

            <div className="lt" style={{ cursor: "pointer" }}>
              <img src={ab6} alt="" />
              <span className="p">By Ayomide Olalekan</span>
              <span className="secondary">Tuesday, 18 May 2023</span>
            </div>
          </div>
        </div>
      </div>

      <div className="trend">
        <h3>Trending Topics</h3>
        <h1>
          Inspirations and <br /> Tips for Decor
        </h1>
        <hr />
      </div>

      <div className="b-menu">
        <a href="">All</a>
        <a href="">Tips and Trick</a>
        <a href="">Interior Design</a>
        <a href="">Design Inspiration</a>
      </div>

      <div className="blogpost" onClick={() => navigate("/blogpost/4")}>
        <div className="img">
          <img src={p1} alt="" />
        </div>

        <div className="lower">
          <h3>Tips and Trick</h3>
          <h1>Holiday Decorating: Tips and Tricks for a Festive Home</h1>

          <p>
            This blog offers ideas and inspiration for decorating your home for
            the holidays, including DIY projects and creative decor ideas.
          </p>

          <div className="lt">
            <img src={ab6} alt="" />
            <span className="p">By Ayomide Olalekan</span>
            <span className="secondary">Tuesday, 18 May 2023</span>
          </div>
        </div>
      </div>

      <div className="blogpost" onClick={() => navigate("/blogpost/2")}>
        <div className="img">
          <img src={p2} alt="" />
        </div>

        <div className="lower">
          <h3>Interior Designs</h3>
          <h1>Creating a Zen Space: How to Design a Relaxing Home</h1>

          <p>
            This blog provides tips on creating a peaceful and relaxing home
            environment, including ideas for incorporating natural elements and
            decluttering.
          </p>

          <div className="lt">
            <img src={ab6} alt="" />
            <span className="p">By Ayomide Olalekan</span>
            <span className="secondary">Tuesday, 18 May 2023</span>
          </div>
        </div>
      </div>

      <div className="blogpost" onClick={() => navigate("/blogpost/3")}>
        <div className="img">
          <img src={p3} alt="" />
        </div>

        <div className="lower">
          <h3>Design Inspiration</h3>
          <h1>
            Vintage Finds: How to Incorporate Antiques into Your Modern Home
          </h1>

          <p>
            This blog provides inspiration and advice on incorporating vintage
            and antique decor into modern home design.
          </p>

          <div className="lt">
            <img src={ab6} alt="" />
            <span className="p">By Ayomide Olalekan</span>
            <span className="secondary">Saturday, 29 February 2023</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
