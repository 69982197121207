import React, { useEffect, useRef } from "react";
import img from "../../assets/blog/BlogImage3big.png";
import imgg from "../../assets/blog/BlogImage3big2.png";
import imggg from "../../assets/blog/BlogImage3big3.png";
import ab6 from "../../assets/blog/blogUser.png";

const BlogPost3 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  return (
    <div className="blog-content">
      <div className="home">
        <div className="content">
          <h1>Holiday Decorating: Tips and Tricks for a Festive Home</h1>
          <p>
            {" "}
            This blog offers ideas and inspiration for decorating your home for
            the holidays, including DIY projects and creative decor ideas.
          </p>

          <div className="intro">
            <img src={ab6} alt="" />

            <div className="data">
              <h6>Ayomide Olalekan</h6>
              <p>Tuesday, 18 May 2023</p>
            </div>
          </div>
        </div>

        <div className="img">
          <img src={img} alt="" />
        </div>
      </div>

      <div className="text">
        <p>
          The holiday season is here, and it's time to start thinking about
          decorating your home. Whether you're hosting family and friends or
          just want to create a festive atmosphere for yourself, there are
          plenty of ways to get your home looking merry and bright. In this
          post, we'll share some tips and tricks for holiday decorating that
          will help you create a warm and welcoming home that's sure to impress.
        </p>
        <h2>Start with a Color Scheme</h2>
        <p>
          When it comes to holiday decorating, a cohesive color scheme can make
          a big difference. Choose a color palette that complements your
          existing décor and use it throughout your home. This will help create
          a cohesive look and make your decorations feel more intentional.
        </p>
        <h2>Focus on the Entryway</h2>
        <p>
          The entryway is the first thing your guests will see when they arrive,
          so it's important to make it welcoming. Hang a festive wreath on the
          front door, add some potted plants or seasonal decorations, and
          consider adding some cozy seating to create a warm and inviting
          atmosphere.
        </p>
        <h2>Mix and Match Textures</h2>
        <p>
          One way to add interest and depth to your holiday décor is by mixing
          and matching textures. Use a variety of materials, such as wool,
          velvet, burlap, and metallics, to add dimension and create a cozy
          feel.
        </p>
        <img src={imgg} alt="" />
        <span>Warm lighting is key</span>
        <h2>Add Warm Lighting </h2>
        <p>
          Warm lighting can make your home feel cozy and inviting during the
          holiday season. Consider adding string lights, candles, or even a
          fireplace to create a warm and welcoming atmosphere.
        </p>
        <h2>Create a Festive Tablescape</h2>
        <p>
          If you're hosting a holiday gathering, a festive tablescape can make
          all the difference. Use seasonal table runners, placemats, and
          napkins, and add some candles or other seasonal décor to create a warm
          and inviting atmosphere.
        </p>
        <h2>Don't Forget the Tree</h2>
        <p>
          No holiday décor is complete without a Christmas tree. Choose a tree
          that complements your existing décor and consider adding a variety of
          ornaments, garlands, and lights to create a beautiful and festive
          centerpiece for your home.
        </p>

        <img src={imggg} alt="" />

        <span>Never forget the tree</span>
        <h2>Add Some Scents</h2>
        <p>
          Scented candles, essential oils, and other fragrances can help create
          a warm and welcoming atmosphere in your home. Consider using scents
          like cinnamon, peppermint, or pine to create a cozy and inviting
          ambiance.
        </p>

        <h2>Get Creative with Gift Wrapping</h2>
        <p>
          Finally, don't forget to get creative with your gift wrapping. Use
          seasonal wrapping paper, ribbons, and bows to add some extra holiday
          cheer to your gifts. You can also consider using unique wrapping
          techniques, such as fabric wrapping or eco-friendly alternatives like
          reusable cloth gift bags.
        </p>

        <p>
          By following these tips and tricks for holiday decorating, you can
          create a warm and inviting home that's perfect for the holiday season.
          Whether you're hosting family and friends or just want to create a
          festive atmosphere for yourself, these tips are sure to help you
          create a beautiful and welcoming home that you'll love spending time
          in.
        </p>
      </div>
    </div>
  );
};

export default BlogPost3;
