import React, { useEffect, useRef, useState } from "react";
import img from "../assets/contact/Rectangle 269.png";
import thankyou from "../assets/contact/lettering thank you.png";

const defaultFeild = {
  email: "",
  subject: "",
  body: "",
};

const Contact = () => {
  const [first, setfirst] = useState(true);

  const [message, setMessage] = useState(defaultFeild);

  const { email, subject, body } = message;

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, [first]);

  return (
    <div className="contact">
      <div className="home">
        <div className="img">
          <img src={img} alt="" />
        </div>
        {first ? (
          <div className="content">
            <h3>Help center</h3>
            <h1> Let's talk</h1>
            <p>
              {" "}
              We'd love to hear from you! Whether you have a question about our
              products, need help with an order, or just want to chat about
              interior design, we're here to help. Fill out the form below and
              we'll get back to you as soon as possible.
            </p>

            <div className="call">
              <p> Call:</p>
              <p className="m-bt"> +234 703 384 6692</p>
              <p> Email:</p>
              <p className="m-bt"> lashinteriors20@gmail.com </p>
              <p> Instagram:</p>
              <p>
                {" "}
                <a href="https://www.instagram.com/lashinteriors/">
                  See us on instagram
                </a>
              </p>
            </div>

            <div className="form">
              <div>
                <p>Name</p>
                <input
                  type="text"
                  placeholder="Enter your name here"
                  name="subject"
                  value={subject}
                  onChange={(e) =>
                    setMessage({ ...message, [e.target.name]: e.target.value })
                  }
                />
              </div>

              <div>
                <p>Subject</p>
                <input
                  type="text"
                  placeholder="Subject"
                  name="email"
                  value={email}
                  onChange={(e) =>
                    setMessage({ ...message, [e.target.name]: e.target.value })
                  }
                />
              </div>

              <div>
                <p>Text</p>
                <textarea
                  type="text"
                  placeholder="..."
                  name="body"
                  value={body}
                  onChange={(e) =>
                    setMessage({ ...message, [e.target.name]: e.target.value })
                  }
                />
              </div>

              <button onClick={() => setfirst((prev) => !prev)}>
                <a href={`mailto:lashinteriors20@gmail.com?subject=${email}&body=${body}`}>
                Submit
                </a> 
                
              </button>
            </div>
          </div>
        ) : (
          <div className="thankyou">
            <img src={thankyou} alt="" />
            <p>
              Thank you for sharing your thoughts.
              <br /> We’ll get back to you as soon
              <br />
              as possible.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Contact;
