import { createContext, useState, useEffect } from "react";

export const SearchContext = createContext({
  setSearchVisible: () => {},
  searchVisible: false,
});

export const SearchProvider = ({ children }) => {
  const [searchVisible, setSearchVisible] = useState(false);

  const value = { searchVisible, setSearchVisible };

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};
