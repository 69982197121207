import React from "react";

const WhyUs = () => {
  return (
    <div className="whyus">
      <div className="title">
        <span>Why use us</span>

        <h1>
          Our Value <br /> Proposition
        </h1>

        <hr />
      </div>

      <div className="ot">
        <h3>Quality Products</h3>
        <p>
          Our furniture and decor is made with the highest-quality materials and
          designed to last.{" "}
        </p>
      </div>
      <div className="ot">
        <h3>Affordable Price</h3>
        <p>
          We know that designing your dream space can be expensive, which is why
          we offer our products at a fair and competitive price.{" "}
        </p>
      </div>
      <div className="ot">
        <h3>Customization</h3>
        <p>
          We offer customizable furniture options that can be tailored to your
          specific needs, ensuring that you get exactly what you want.
        </p>
      </div>
      <div className="ot">
        <h3>Fast and Reliable Shipping</h3>
        <p>
          We know you're excited to start enjoying your new furniture and decor,
          which is why we offer fast and reliable shipping.
        </p>
      </div>
      <div className="ot">
        <h3>Expert Advice</h3>
        <p>
          Our team of design experts is always here to help you make the right
          decisions for your space.
        </p>
      </div>
    </div>
  );
};

export default WhyUs;
