import React, { useState, useContext, useEffect } from "react";
import { CartContext } from "../../context/cart.context";
import { UserDataContext } from "../../context/userData.context";
import SavedAdderessComponent from "./saved address/savedAddress.component";

const CheckoutSavedAddress = ({ clickForward, clickSw, savedAddressRoute }) => {
  const { cartItems, cartCount, cartTotal } = useContext(CartContext);
  const {
    wishlist,
    setWishlist,
    address,
    setAddress,
    alert,
    setAlert,
    slide,
    setslide,
    currentlySelectedAddress,
    setCurrentlySelectedAddress,
  } = useContext(UserDataContext);
  const [selectedOption, setSelectedOption] = useState(null);

  const [currentDate, setCurrentDate] = useState(new Date());
  const [nextCurrentDate, setNextCurrentDate] = useState(new Date());

  // Function to set the date four days ahead
  const setDateFourDaysAhead = () => {
    const newDate = new Date(currentDate);
    const nextNewDate = new Date(nextCurrentDate);
    newDate.setDate(newDate.getDate() + 2);
    nextNewDate.setDate(nextNewDate.getDate() + 4);
    setCurrentDate(newDate);
    setNextCurrentDate(nextNewDate);
  };

  useEffect(() => {
    setDateFourDaysAhead();
    setSelectedOption(0);

    setCurrentlySelectedAddress(address[0]);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  return (
    <>
      <div class="one">
        <div class="menu">
          <span className="active">Address</span>
          <span>{">"}</span>
          <span>Confirmation</span>
        </div>

        <div className="inputs">
          {address?.map((add, i) => {
            return (
              <SavedAdderessComponent
                add={add}
                i={i}
                setRadio={() => setSelectedOption(i)}
                radio={selectedOption}
                clickForward={savedAddressRoute}
              />
            );
          })}

          <p
            onClick={() => {
              clickSw();
            }}
          >
            + Add New Address
          </p>
        </div>
      </div>
      <div class="two">
        <div className="f">
          <h1>Order Summary</h1>
          <div className="main">
            <div className="op">
              <span>Price</span>
              <span>₦ {cartTotal}</span>
            </div>

            <div className="op">
              <span>Discount</span>
              <span>₦0</span>
            </div>

            <div className="op">
              <span>Shipping</span>
            </div>

            <hr />

            <div className="op dark">
              <span>Total</span>
              <span>₦ {cartTotal}</span>
            </div>

            <div className="op ">
              <span>
                Estimated Delivery <span> {currentDate.toDateString()}</span> -
                <span> {nextCurrentDate.toDateString()}</span>
              </span>
            </div>

            <button
              className="btn-sec"
              onClick={() => {
                if (address.length > 0) clickForward();
              }}
            >
              Confirmation
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckoutSavedAddress;
