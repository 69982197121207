import { doc, getDoc } from "firebase/firestore";
import { db } from "../../utils/firebase/firebase.utils";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/user.context";

const Dashboard = ({ AddAddress, userData, checkAddress }) => {
  const { currentUser } = useContext(UserContext);
  const [order, setorder] = useState([]);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    const getOrders = async () => {
      const userRef = doc(db, "users", currentUser?.uid);

      const docSnap = await getDoc(userRef);

      const data = docSnap.data();

      if (data?.paymentReference) {
        setorder(data?.paymentReference);
      }
      setloading(false);
    };
    if (currentUser) {
      getOrders();
    }
  }, [currentUser]);
  return (
    <>
      <div className="flex">
        <div className="ot">
          <h2>Account Details</h2>
          <p>
            {`${userData?.firstName ? userData?.firstName : ""} ${
              userData?.firstName ? userData?.lastName : ""
            }`}
            <br />
            {`${userData?.email ? userData?.email : ""} `}
          </p>
        </div>

        <div className="ot">
          <h2>Address</h2>
          <p className="other">
            {checkAddress.length > 0
              ? "Change default shipping address"
              : "No default shipping address available"}
          </p>
          <a onClick={() => AddAddress()}>ADD DEFAULT ADDRESS</a>
        </div>
      </div>

      <div className="flex">
        <div className="et">
          <div style={{ textAlign: "center" }}>
            <span>{loading ? "-" : order.length}</span>
            <p>Total Orders</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
