import React, { useContext, useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { ClipLoader } from "react-spinners";
import { Link, useNavigate } from "react-router-dom";

import {
  createAuthUserWithEmailAndPassword,
  createUserDocumentFromAuth,
} from "../../utils/firebase/firebase.utils";
import { CartContext } from "../../context/cart.context";
import { UserDataContext } from "../../context/userData.context";

const defaultFormFields = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
};

const SignUpComponent = () => {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);

  const [formFields, setFormFields] = useState(defaultFormFields);
  const { firstName, lastName, email, password } = formFields;
  const { cartItems } = useContext(CartContext);

  const {
    wishlist,
    setWishlist,
    address,
    setAddress,
    alert,
    setAlert,
    slide,
    setslide,
    currentlySelectedAddress,
    setCurrentlySelectedAddress,
  } = useContext(UserDataContext);

  const navigate = useNavigate();

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const { user } = await createAuthUserWithEmailAndPassword(
        email,
        password
      );

      await createUserDocumentFromAuth(user, {
        firstName,
        lastName,
        phone: value,
      });
      if (cartItems.length > 0) {
        navigate("/cart");
      } else {
        navigate("/shop");
      }

      setAlert({
        color: "blue",
        text: "Welcome, Login Successful",
      });

      setslide(true);

      resetFormFields();
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        setAlert({
          color: "red",
          text: "User sign in failed, email already in use",
        });
      } else {
        setAlert({
          color: "red",
          text: "User sign in failed",
        });
      }

      setslide(true);
    }
    setLoading(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormFields({ ...formFields, [name]: value });
  };

  return (
    <form>
      <h1>Sign up now</h1>

      <div class="fls">
        <div class="fc">
          <label for="firstName" class="fl">
            First name
          </label>
          <input
            type="text"
            name="firstName"
            onChange={handleChange}
            class="firstName_input"
            required
          />
        </div>

        <div class="lc">
          <label for="lastName" class="ll">
            Last name
          </label>
          <input
            type="text"
            name="lastName"
            class="lastName_input"
            onChange={handleChange}
            required
          />
        </div>
      </div>

      <div class="es">
        <div class="eli">
          <label for="email" class="el">
            Email address
          </label>
          <input
            type="text"
            name="email"
            class="ei"
            onChange={handleChange}
            required
          />
        </div>
      </div>

      <div class="ps">
        <div class="pc">
          <label for="phone" class="pl">
            Phone number
          </label>

          <div class="code_selector">
            <PhoneInput
              international
              defaultCountry="NG"
              value={value}
              onChange={setValue}
            />
          </div>
        </div>
      </div>

      <div class="ps">
        <div class="pc">
          <div class="label_and_passwordView">
            <label for="password" class="password_label">
              Password
            </label>
          </div>

          <input
            type="password"
            name="password"
            class="password_input"
            id="passwordValue"
            required
            onChange={handleChange}
          />
          <p class="bottom-line">
            Use 8 or more characters with a mix of letters,numbers & symbols
          </p>
        </div>
      </div>

      <div class="ts">
        <div class="tc">
          <div class="terms_box">
            <div class="tc">
              <input
                type="checkbox"
                name="terms"
                class="terms_check1"
                required
              />
            </div>

            <div class="tl">
              <label for="terms">
                By creating an account,i agree to our
                <Link to="/termsofuse"> Terms of use </Link> and
                <Link to="/privacypolicy"> Privacy Policy </Link>
              </label>
            </div>
          </div>
          <div class="terms_box">
            <div class="tc">
              <input
                type="checkbox"
                name="terms2"
                class="terms_check2"
                required
              />
            </div>
            <div class="tl">
              <label for="terms2">
                By creating an account,i am also consenting to recieving SMS
                messages and emails,including product new feature
                updates,events, and marketting promotions.
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="sbs">
        <button onClick={handleSubmit} disabled={loading} class="sign_up">
          {loading ? <ClipLoader size={20} color="white" /> : "Sign up"}
        </button>

        <p class="login">
          Already have an account? <Link to="/signin"> Log in</Link>
        </p>
      </div>
    </form>
  );
};

export default SignUpComponent;
