import { useContext, useEffect, useRef, useState } from "react";
import { FaCartPlus, FaStar, FaStarHalf } from "react-icons/fa";
import { MdAddShoppingCart } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { CartContext } from "../../context/cart.context";

export const FakePrice = (price) => {
  const date = new Date();
  const month = date.getMonth() + 2;
  let fakePrice = Math.floor(
    Number(price?.split(",")?.join("")) * ((100 + month) / 100)
  );
  return `${fakePrice}`;
};
const Product = ({
  name,
  slug,
  category,
  image,
  price,
  brand,
  rating,
  numReviews,
  description,
  Available_colors,
  chair_settings,
  product,
}) => {
  const { addItemToCart, cartItems } = useContext(CartContext);
  const [stockp, setstockp] = useState(true);

  useEffect(() => {
    const foundItem = cartItems.find((item) => {
      return item?.Name === product?.Name;
    });

    if (
      foundItem &&
      foundItem?.quantity &&
      foundItem?.quantity >= product?.UnitsInStock
    )
      setstockp(false);
  }, []);

  return (
    <div className="product" style={{ cursor: "pointer" }}>
      <div className="img">
        <Link to={`/product/${slug?.toLowerCase("").split(" ").join("")}`}>
          <img
            src={`http://u596h.erprev.com/res/img/items/${product?.ThumbImage}`}
            alt=""
            style={{
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          />
        </Link>

        {product?.UnitsInStock && stockp ? (
          <div
            className="btn"
            onClick={() => {
              addItemToCart(product);
              const foundItem = cartItems.find((item) => {
                return item?.Name === product?.Name;
              });

              if (foundItem?.quantity >= product?.UnitsInStock)
                setstockp(false);
            }}
          >
            <p>Add to Cart</p>
            <MdAddShoppingCart />
          </div>
        ) : (
          <div className="btn">
            <p>Out of stock</p>
            <MdAddShoppingCart />
          </div>
        )}
      </div>

      <Link to={`/product/${slug?.toLowerCase("").split(" ").join("")}`}>
        <h4>{category}</h4>
        <h3>{name}</h3>
        <p>{description !== "<p><br></p>" ? description : ""}</p>
        <h3 id="price">
          ₦ {price} <br />
          <span
            style={{
              textDecoration: "line-through",
              fontSize: "70%",
              color: "gray",
            }}
          >
            ₦ {FakePrice(price)}
          </span>
        </h3>
      </Link>
    </div>
  );
};

export default Product;
