import React, { useContext, useState } from "react";
import "react-phone-number-input/style.css";
import { ClipLoader } from "react-spinners";
import { Link, useNavigate } from "react-router-dom";

import { signInAuthUserWithEmailAndPassword } from "../../utils/firebase/firebase.utils";
import { CartContext } from "../../context/cart.context";
import { UserDataContext } from "../../context/userData.context";

const defaultFormFields = {
  email: "",
  password: "",
};

const SignInComponent = ({ cfw }) => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { email, password } = formFields;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { cartItems } = useContext(CartContext);
  const {
    wishlist,
    setWishlist,
    address,
    setAddress,
    alert,
    setAlert,
    slide,
    setslide,
    currentlySelectedAddress,
    setCurrentlySelectedAddress,
  } = useContext(UserDataContext);

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    try {
      const res = await signInAuthUserWithEmailAndPassword(email, password);

      resetFormFields();
      setAlert({
        color: "blue",
        text: "Welcome, Login Successful",
      });

      setslide(true);

      if (cartItems.length > 0) {
        navigate("/cart");
      } else {
        navigate("/shop");
      }
    } catch (error) {
      console.log("user sign in failed", error);

      setAlert({
        color: "red",
        text: "User sign in failed",
      });
      setslide(true);
    }
    setLoading(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormFields({ ...formFields, [name]: value });
  };

  return (
    <form className="signincomponent">
      <h1>Welcome</h1>

      <div class="es">
        <div class="eli">
          <label for="email" class="el">
            Email address
          </label>
          <input
            type="text"
            name="email"
            class="ei"
            required
            onChange={handleChange}
          />
        </div>
      </div>

      <div class="ps">
        <div class="pc">
          <div class="label_and_passwordView">
            <label for="password" class="password_label">
              Password
            </label>
          </div>

          <input
            type="password"
            name="password"
            class="password_input"
            id="passwordValue"
            required
            onChange={handleChange}
          />

          <div class="terms_box">
            <div class="tc">
              <input type="checkbox" name="terms" class="terms_check1" />
            </div>

            <div class="tl">
              <label for="terms">Remember me</label>
            </div>
          </div>

          <p class="bottom-line">
            By continuing, you agree to the{" "}
            <Link to="/termsofuse"> Terms of use </Link> and
            <Link to="/privacypolicy"> Privacy Policy </Link>
          </p>
        </div>
      </div>

      <div class="ts">
        <div class="tc">
          <div class="terms_box">
            <div class="tl">
              <label
                for="terms2"
                onClick={() => {
                  cfw()
                }}
              >
                <a>Forgot your password?</a>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="sbs">
        <button disabled={loading} class="sign_up" onClick={handleSubmit}>
          {loading ? <ClipLoader size={20} color="white" /> : "   Sign In"}
        </button>

        <p class="login ">
          Don’t have an account? <Link to="/signup"> Sign up</Link>
        </p>
      </div>
    </form>
  );
};

export default SignInComponent;
