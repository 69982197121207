import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dashboard from "../components/userDashboard/Dashboard";
import Address from "../components/userDashboard/Address";
import AddingAddress from "../components/userDashboard/AddingAddress";
import OrderList from "../components/userDashboard/OrderList";
import Confirmation from "../components/checkout/Confirmation";
import ProductConfirmed from "../components/checkout/ProductConfirmed";
import ShowingProducts from "../components/userDashboard/ShowingProducts";
import WishList from "../components/userDashboard/WishList";

import { UserDataContext } from "../context/userData.context";
import { UserContext } from "../context/user.context";
import { signOutUser } from "../utils/firebase/firebase.utils";

const menu = ["Dashboard", "Addresses", "Orders", "Wishlist"];

const UserDashboard = () => {
  const { page, setPage, address } = useContext(UserDataContext);
  const { userData, setUserData, currentUser } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  useEffect(() => {
    if (!currentUser) {
      navigate("/signin");
    }
  }, [currentUser]);

  return (
    <div className="userDashboard w-full min-h-screen grid place-items-center m-auto">
      {/**part 1 */}
      <div className="userDashboard__list">
        <div className="menu">
          <ul>
            {menu.map((m, i) => (
              <li
                className={i + 1 === page ? "active" : ""}
                onClick={() => setPage(i + 1)}
              >
                <span>{m}</span>
              </li>
            ))}

            {currentUser && (
              <li
                onClick={() => {
                  setUserData({});
                  signOutUser();
                  navigate("/signin");
                }}
              >
                <span>Log Out</span>
              </li>
            )}
          </ul>
        </div>
      </div>

      {/**part 2 */}
      <div className="userDashboard__content">
        <div className="content-child">
          <div className="text">
            <h3>
              {currentUser ? `Welcome Back, ${userData?.firstName}` : `Welcome`}
            </h3>
            <p>Here is the information about your details</p>
          </div>
        </div>

        {page === 1 ? (
          <Dashboard
            AddAddress={() => setPage(6)}
            userData={userData}
            checkAddress={address}
          />
        ) : page === 2 ? (
          <Address AddAddress={() => setPage(6)} />
        ) : page === 3 ? (
          <OrderList />
        ) : page === 5 || page === 4 ? (
          <WishList />
        ) : page === 6 ? (
          <AddingAddress goBackToAddress={() => setPage(2)} />
        ) : page === 8 ? (
          <AddingAddress />
        ) : page === 9 ? (
          <AddingAddress />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default UserDashboard;
