import React, { useContext, useEffect, useState } from "react";
import SearchSmall from "../../assets/search/search_icn_small.png";
import SearchSmallMobie from "../../assets/search/search_icon_small_mobile.png";
import Cancel from "../../assets/search/cancle.png";
import CancelSmall from "../../assets/search/cance_icon_small_mobile.png";
import SearchBig from "../../assets/search/big_search_icon.png";
import SearchBigSmall from "../../assets/search/search_icon_big_mobile.png";

import { SearchContext } from "../../context/search.context";
import { useMediaQuery } from "react-responsive";
import { PorductContext } from "../../context/product.context";
import { useNavigate } from "react-router-dom";

function getObjectBySlug(arr, searchData) {
  let res = [];
  arr?.map((item) => {
    if (item?.Name?.toLowerCase().includes(searchData)) {
      res.push(item);
    }
  });
  return res;
}

const Search = () => {
  const { searchVisible, setSearchVisible } = useContext(SearchContext);
  const { product, setCurrentOpenProduct } = useContext(PorductContext);
  const [searchData, setsearchData] = useState("");
  const [searchSugestion, setSearchSugestion] = useState([]);
  const navigate = useNavigate();

  const routeToProduct = (item) => {
    setCurrentOpenProduct(item);
    setSearchVisible(false);
    let slug = item.slug;
    console.log(slug);
    navigate(`/product/${item?.Name?.toLowerCase("").split(" ").join("")}`);
  };

  useEffect(() => {
    const res = getObjectBySlug(product, searchData.toLowerCase());
    setSearchSugestion([...res]);
    
  }, [searchData, product]);

  const searchItem = () => {
    setSearchVisible(false);
  };

  const res = useMediaQuery({ query: "(max-width: 800px)" });

  return (
    <div className="search">
      {searchVisible && (
        <div
          id="search-overlay"
          class="block"
          style={{
            zIndex: "100000000000000000000000000000000",
          }}
        >
          <div class="centered">
            <div className="one">
              <img src={res ? SearchSmallMobie : SearchSmall} alt="" />

              <input
                type="text"
                placeholder="Search something "
                value={searchData}
                onChange={(e) => {
                  setsearchData(e.target.value);
                }}
              />
              <img
                src={res ? CancelSmall : Cancel}
                alt=""
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (searchData.length === 0) {
                    searchItem();
                  } else {
                    setsearchData("");
                  }
                }}
              />
            </div>
            {searchSugestion.slice(0, 3).map((item) => (
              <div
                className="one"
                onClick={() => routeToProduct(item)}
                style={{ cursor: "pointer" }}
              >
                <h1>{item.Name}</h1>
                <img
                  src={res ? SearchBigSmall : SearchBig}
                  className="search_big"
                  onClick={() => searchItem()}
                  style={{ cursor: "pointer" }}
                  alt=""
                />
              </div>
            ))}

            <div className="two"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Search;
