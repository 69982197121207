import { useContext, useState, useEffect } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import { UserContext } from "../context/user.context";
import Axios from "axios";
import SignInComponent from "../components/login/SignIn";
import img from "../assets/home/Mask.png";
import EmailVerified from "../components/login/EmailVerified";
import VerificationModal from "../components/login/VerificationModal";

export default function SignIn() {
  const [page, setpage] = useState(1);

  const [email, setemail] = useState("");
  console.log(email);
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  if (page === 1) {
    return (
      <div className="sign_up_form" style={{ backgroundImage: `url(${img}) ` }}>
        <SignInComponent cfw={() => setpage(2)} />
      </div>
    );
  } else if (page === 2) {
    return (
      <div className="sign_up_form" style={{ backgroundImage: `url(${img}) ` }}>
        <EmailVerified
          cfw={() => setpage(3)}
          setemail={(email) => setemail(email)}
          email={email}
        />
      </div>
    );
  } else if (page === 3) {
    return (
      <div className="sign_up_form" style={{ backgroundImage: `url(${img}) ` }}>
        <VerificationModal cfw={() => setpage(1)} email={email} />
      </div>
    );
  }
}

// <div class="ps">
//         <div class="pc">
//           <div class="label_and_passwordView">
//             <label for="password" class="password_label">
//               New Password
//             </label>
//             <div class="visibility">
//               <img src="images/invisible.png" alt="" id="visibility" />

//             </div>
//           </div>

//           <input
//             type="text"
//             name="password"
//             class="password_input"
//             id="passwordValue"
//             required
//           />
//         </div>
//       </div>
