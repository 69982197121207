import { FaStar, FaStarHalf } from "react-icons/fa";

const Review = () => {
  return (
    <div className="review">
      <div className="heading">
        <div className="info">
          <img
            src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80"
            alt=""
          />
          <div>
            <h3>Adeyemi Adelokan</h3>
            <p>June 28, 2023</p>
          </div>
        </div>
        <div className="stars">
          <span>
            <FaStar color="gold" />
            <FaStar color="gold" />
            <FaStar color="gold" />
            <FaStar color="gold" />
            <FaStarHalf color="gold" />
          </span>
        </div>
      </div>
      <p id="txt">
        Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil
        impedit quo minus id quod maxime placeat facere possimus, omnis voluptas
        assumenda.
      </p>
    </div>
  );
};

export default Review;
