import React, { useContext, useEffect, useState } from "react";
import CartItem from "../components/cart/CartItem";
import { CartContext } from "../context/cart.context";
import CartPageComponent from "../components/cart/CartPageComponent";
import CheckoutNewAddress from "../components/checkout/CheckoutAddress";
import CheckoutSavedAddress from "../components/checkout/CheckoutSavedAddress";
import ProductConfirmed from "../components/checkout/ProductConfirmed";
import Confirmation from "../components/checkout/Confirmation";
import PaymentCheckout from "../components/checkout/PaymentCheckout";

const Cart = () => {
  const { cartItems, cartCount, cartTotal } = useContext(CartContext);

  const [page, setPage] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, [page]);

  useEffect(() => {
    setPage(1);
  }, []);

  if (page === 1) {
    return (
      <section className="cart min-h-screen">
        <CartPageComponent clickForward={() => setPage(2)} />
      </section>
    );
  } else if (page === 2) {
    return (
      <section className="checkout min-h-screen">
        <CheckoutSavedAddress
          clickForward={() => setPage(6)}
          clickSw={() => setPage(3)}
          savedAddressRoute={() => setPage(3)}
        />
      </section>
    );
  } else if (page === 3) {
    return (
      <section className="checkout min-h-screen">
        <CheckoutNewAddress clickForward={() => setPage(6)} />
      </section>
    );
  } else if (page === 6) {
    return (
      <section className="checkout min-h-screen">
        <Confirmation
          clickForward={() => setPage(7)}
          clickBack={() => setPage(3)}
        />
      </section>
    );
  } else if (page === 7) {
    return (
      <section className="checkout min-h-screen">
        <ProductConfirmed />
      </section>
    );
  }
};

export default Cart;

// PaymentCheckout
