import { createContext, useState, useEffect } from "react";
import data from "../data.js";
import axios from "axios";

export const PorductContext = createContext({
  setProduct: () => {},
  product: [],
  setCurrentOpenProduct: () => {},
  currentOpenProduct: {},
});

export const PorductProvider = ({ children }) => {
  const [product, setProduct] = useState([]);
  const [currentOpenProduct, setCurrentOpenProduct] = useState({});
  const [numberOfProducts, setNumberOfProducts] = useState(0);
  let productsWithQuantity = [];

  useEffect(() => {
    const apiPromises = [
      axios.get("https://lash-api.vercel.app/api/stocks"),
      axios.get("https://lash-backend.vercel.app/api/products"),
    ];

    Promise.all(apiPromises)
      .then((res) => {
        productsWithQuantity = [...res[0].data];

        const data = res[1].data?.filter((item) => {
          if (
            item?.Name !== "." &&
            item.Price !== "" &&
            item?.Name !== "" &&
            item?.Name !== " " &&
            item?.Class === "Chair"
          ) {
            const rq = productsWithQuantity.find(
              (itx) => itx?.Product === item?.Name
            );

            if (rq) {
              return {
                ...item,
                UnitsInStock: rq?.UnitsInStock,
                Category: rq?.Category,
              };
            }
          }
        });

        setProduct([...data]);
        setNumberOfProducts(res[1].data.length);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }, []);

  const value = {
    product,
    setProduct,
    currentOpenProduct,
    setCurrentOpenProduct,
    numberOfProducts,
  };

  return (
    <PorductContext.Provider value={value}>{children}</PorductContext.Provider>
  );
};
