import { FaStar, FaStarHalf } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { FakePrice } from "../shop/Product";

const Suggestion = ({ product }) => {
  const navigate = useNavigate();
  return (
    <div
      className="suggestion"
      onClick={() =>
        navigate(
          `/product/${product?.Name?.toLowerCase("").split(" ").join("")}`
        )
      }
      style={{
        cursor: "pointer",
      }}
    >
      <img
        src={`http://u596h.erprev.com/res/img/items/${product?.ThumbImage}`}
        alt=""
      />
      <h4> {"N/A" !== product?.Category ? product?.Category : ""}</h4>
      <h3>{product?.Name}</h3>
      <p>Upgrade your space with our products</p>
      <h3 id="price">
        ₦ {product?.Price} <br />
        <span
          style={{
            textDecoration: "line-through",
            fontSize: "70%",
            color: "gray",
          }}
        >
          ₦ {FakePrice(product?.Price)}
        </span>
      </h3>
    </div>
  );
};

export default Suggestion;
