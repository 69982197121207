import React, { useContext, useEffect, useRef, useState } from "react";
import logo from "../assets/NewLogo.png";
import search from "../assets/sbn.png";
import cart from "../assets/cart.png";

import { BsCart3, BsChevronDown, BsChevronUp } from "react-icons/bs";
import { FaBars, FaCaretUp, FaSearch, FaTimes } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { CartContext } from "../context/cart.context";
import { SearchContext } from "../context/search.context";
import { UserDataContext } from "../context/userData.context";
import { UserContext } from "../context/user.context";
import { signOutUser } from "../utils/firebase/firebase.utils";

export const Nav = () => {
  const navigate = useNavigate();
  const [showMnav, setShowMnav] = useState(false);
  const [topNav, setTopNav] = useState(true);
  const res = useMediaQuery({ query: "(max-width: 800px)" });
  const [dropDownVisible, setDropDownVisible] = useState(false);
  const dropdownRef = useRef(null);

  const { cartCount } = useContext(CartContext);
  const { searchVisible, setSearchVisible } = useContext(SearchContext);
  const { userData, setUserData, currentUser } = useContext(UserContext);

  const { page, setPage } = useContext(UserDataContext);

  const hideNav = () => {
    if (window.scrollY > 0) {
      setTopNav(false);
      document.querySelector("body").style.paddingTop = "87.45px";
    } else {
      setTopNav(true);
      document.querySelector("body").style.paddingTop = "128.25px";
    }
  };

  useEffect(() => {
    if (!res) {
      window.addEventListener("scroll", hideNav);
      setShowMnav(false);
    } else {
      document.querySelector("body").style.paddingTop = "80.97px";
      setDropDownVisible(false);
    }
    return () => {
      window.removeEventListener("scroll", hideNav);
    };
  }, [res]);

  useEffect(() => {
    if (showMnav) {
      document.querySelector("body").style.overflow = "hidden";
    } else {
      document.querySelector("body").style.overflow = "scroll";
    }
  }, [showMnav]);

  const [dropdownMobile, setDropdownMobile] = useState(true);

  const products = [
    "All products",
    "Office Chairs",
    "Dining Chairs",
    "Sofas",
    "Tables",
    "Lounge Chairs",
    "Accent Chairs",
    "High Stools",
    "Rugs",
  ];

  const link = [
    "officechairs",
    "diningchairs",
    "sofas",
    "tables",
    "loungechairs",
    "accentchairs",
    "highstools",
    "rugs",
  ];

  const DropDown = ({ refVal }) => {
    return (
      <div
        ref={refVal}
        style={{
          position: "absolute",
          bottom: "-350px",
          zIndex: "9999",
          width: "360px",
        }}
      >
        <FaCaretUp
          size={35}
          style={{ position: "absolute", top: "-20px", left: "10px" }}
          color="#84C029"
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "20px",
            background: "#84C029",
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
          }}
        >
          <h3 style={{ color: "white" }}>Shop</h3>
          <FaTimes
            onClick={() => setDropDownVisible(false)}
            style={{ cursor: "pointer" }}
            color="white"
          />
        </div>
        <div
          style={{
            padding: "25px 35px",
            background: "#191A1C",
            borderBottomLeftRadius: "12px",
            borderBottomRightRadius: "12px",
          }}
        >
          <p
            style={{ color: "white", paddingBottom: "20px", fontSize: "17px" }}
            onClick={() => {
              navigate("/shop");
              setDropDownVisible(false);
            }}
          >
            All Products
          </p>
          <ul
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto",
              rowGap: "20px",
              columnGap: "10px",
            }}
          >
            <li onClick={() => setDropDownVisible(false)}>
              <Link style={{ fontSize: "14px" }} to="OfficeChairs">
                Office Chairs
              </Link>
            </li>
            <li onClick={() => setDropDownVisible(false)}>
              <Link style={{ fontSize: "14px" }} to="DiningChairs">
                Dining Chairs
              </Link>
            </li>
            <li onClick={() => setDropDownVisible(false)}>
              <Link style={{ fontSize: "14px" }} to="Sofas">
                Sofas
              </Link>
            </li>
            <li onClick={() => setDropDownVisible(false)}>
              <Link style={{ fontSize: "14px" }} to="Tables">
                Tables
              </Link>
            </li>
            <li onClick={() => setDropDownVisible(false)}>
              <Link style={{ fontSize: "14px" }} to="LoungeChairs">
                Lounge Chairs
              </Link>
            </li>
            <li onClick={() => setDropDownVisible(false)}>
              <Link style={{ fontSize: "14px" }} to="AccentChairs">
                Accent Chairs
              </Link>
            </li>
            <li onClick={() => setDropDownVisible(false)}>
              <Link style={{ fontSize: "14px" }} to="HighStools">
                High Stools
              </Link>
            </li>
            <li onClick={() => setDropDownVisible(false)}>
              <Link style={{ fontSize: "14px" }} to="Rugs">
                Rugs
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  return (
    <nav className="biggerNav" style={{ zIndex: "9999" }}>
      {topNav && (
        <div className="top-nav">
          <a id="ig" href="https://www.instagram.com/lashinteriors/">
            Instagram
          </a>
          <div className="right">
            <div
              className="prof"
              onClick={() => navigate("/dashboard")}
              style={{ cursor: "pointer" }}
            >
              <p>{`${userData?.firstName ? userData?.firstName : ""} ${
                userData?.firstName ? userData?.lastName : ""
              }`}</p>
            </div>
            <ul>
              <li
                onClick={() => navigate("/faq")}
                style={{ cursor: "pointer" }}
              >
                <a>FAQs</a>
              </li>
              <li onClick={() => navigate("/termsofuse")}>
                <a>Terms of Use</a>
              </li>
              {currentUser?.uid ? (
                <li
                  className="links2"
                  onClick={() => {
                    setUserData({});
                    signOutUser();
                  }}
                >
                  <span
                    style={{
                      color: "white",
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                  >
                    Logout
                  </span>
                </li>
              ) : (
                <li className="links2" onClick={() => navigate("/signin")}>
                  <span
                    style={{
                      color: "white",
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                  >
                    {" "}
                    Login
                  </span>
                </li>
              )}
            </ul>
          </div>
        </div>
      )}
      <div id="desktopMenu">
        <div>
          <img src={logo} alt="" id="logo" />
        </div>
        <div className="flex-row" style={{ gap: "2rem" }}>
          <Link to="/">Home</Link>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: "7px",
              cursor: "pointer",
              position: "relative",
            }}
          >
            <a onClick={() => setDropDownVisible(!dropDownVisible)} href="#0">
              Shop
            </a>
            <BsChevronDown
              onClick={() => setDropDownVisible(!dropDownVisible)}
              color="white"
              size={20}
            />
            {dropDownVisible && <DropDown refVal={dropdownRef} />}
          </div>
          <Link to="/blog">Blog</Link>
          <Link to="/aboutus">About Us</Link>
          <Link to="/contact">Contact</Link>

          <div className="menu">
            <img
              src={search}
              alt=""
              onClick={() => setSearchVisible(true)}
              style={{ cursor: "pointer" }}
            />
            <Link to="/cart">
              <img
                style={{ width: "23px", height: "23px" }}
                src={cart}
                alt=""
              />
            </Link>

            <div
              className="span"
              onClick={() => navigate("/cart")}
              style={{ cursor: "pointer" }}
            >
              <p className="num">{cartCount}</p>
            </div>
          </div>
        </div>
      </div>
      <div id="mobileMenu">
        <div className="item-1">
          <div>
            <img src={logo} alt="" id="logo" />
          </div>
          <div
            id="mob"
            style={{ alignItems: "center", columnGap: 15, cursor: "pointer" }}
          >
            <FaSearch
              size={17}
              color="white"
              onClick={() => setSearchVisible(true)}
              style={{ cursor: "pointer", display: "block" }}
            />

            <div
              style={{ position: "relative", display: "block" }}
              onClick={() => navigate("/cart")}
            >
              <BsCart3 size={22} style={{ fontWeight: "bold" }} color="white" />
              <span
                style={{
                  color: "white",
                  position: "absolute",
                  top: "0px",
                  right: "-2px",
                  fontSize: "8px",
                  background: "#84C029",
                  width: "11px",
                  height: "11px",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: "11px",
                }}
              >
                {cartCount}
              </span>
            </div>

            <FaBars
              id="hamburger"
              onClick={() => setShowMnav(!showMnav)}
              style={{ cursor: "pointer", display: "block" }}
            />
          </div>
        </div>
        {showMnav && (
          <div className="flex-col mobile--items" style={{ gap: "2rem" }}>
            <div className="hd">
              <div>
                <img src={logo} alt="" id="logo" />
              </div>
              <FaTimes
                onClick={() => setShowMnav(false)}
                color="#84C029"
                size={23}
              />
            </div>
            <div className="links1">
              <Link onClick={() => setShowMnav(false)} to="/">
                Home
              </Link>
              <div
                style={{
                  borderBottom: "1px solid rgb(95, 95, 95)",
                  padding: "15px 0",
                }}
              >
                <div
                  onClick={() => setDropdownMobile(!dropdownMobile)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ fontSize: "1.1rem", color: "white" }}>Shop</p>
                  {!dropdownMobile ? (
                    <BsChevronUp size={23} color="white" />
                  ) : (
                    <BsChevronDown size={23} color="white" />
                  )}
                </div>
                {!dropdownMobile && (
                  <div
                    style={{
                      color: "white",
                      display: "flex",
                      flexDirection: "column",
                      paddingTop: "11px",
                    }}
                  >
                    <Link
                      onClick={() => setShowMnav(false)}
                      to="/shop"
                      style={{
                        opacity: ".8",
                        borderBottom: "none",
                        fontSize: "12px",
                      }}
                    >
                      All products
                    </Link>
                    <Link
                      onClick={() => setShowMnav(false)}
                      to="/OfficeChairs"
                      style={{
                        opacity: ".8",
                        borderBottom: "none",
                        fontSize: "12px",
                      }}
                    >
                      Office Chairs
                    </Link>
                    <Link
                      onClick={() => setShowMnav(false)}
                      to="/DiningChairs"
                      style={{
                        opacity: ".8",
                        borderBottom: "none",
                        fontSize: "12px",
                      }}
                    >
                      Dining Chairs
                    </Link>
                    <Link
                      onClick={() => setShowMnav(false)}
                      to="/Sofas"
                      style={{
                        opacity: ".8",
                        borderBottom: "none",
                        fontSize: "12px",
                      }}
                    >
                      Sofas
                    </Link>
                    <Link
                      onClick={() => setShowMnav(false)}
                      to="/Tables"
                      style={{
                        opacity: ".8",
                        borderBottom: "none",
                        fontSize: "12px",
                      }}
                    >
                      Tables
                    </Link>
                    <Link
                      onClick={() => setShowMnav(false)}
                      to="/LoungeChairs"
                      style={{
                        opacity: ".8",
                        borderBottom: "none",
                        fontSize: "12px",
                      }}
                    >
                      Lounge Chairs
                    </Link>
                    <Link
                      onClick={() => setShowMnav(false)}
                      to="/AccentChairs"
                      style={{
                        opacity: ".8",
                        borderBottom: "none",
                        fontSize: "12px",
                      }}
                    >
                      Accent Chairs
                    </Link>
                    <Link
                      onClick={() => setShowMnav(false)}
                      to="/HighStools"
                      style={{
                        opacity: ".8",
                        borderBottom: "none",
                        fontSize: "12px",
                      }}
                    >
                      High Stools
                    </Link>
                    <Link
                      onClick={() => setShowMnav(false)}
                      to="/Rugs"
                      style={{
                        opacity: ".8",
                        borderBottom: "none",
                        fontSize: "12px",
                      }}
                    >
                      Rugs
                    </Link>
                  </div>
                )}
              </div>
              <Link onClick={() => setShowMnav(false)} to="/blog">
                Blog
              </Link>
              <Link onClick={() => setShowMnav(false)} to="/aboutus">
                About Us
              </Link>
              <Link onClick={() => setShowMnav(false)} to="/contact">
                Contact
              </Link>
            </div>
            <div className="links2">
              <h3>My Account</h3>
              <Link
                onClick={() => {
                  setShowMnav(false);
                  setPage(1);
                }}
                to="/dashboard"
              >
                Dashboard
              </Link>
              <Link
                onClick={() => {
                  setShowMnav(false);
                  setPage(2);
                }}
                to="/dashboard"
              >
                Addresses
              </Link>

              <Link
                onClick={() => {
                  setShowMnav(false);
                  setPage(3);
                }}
                to="/dashboard"
              >
                Orders
              </Link>
              <Link
                onClick={() => {
                  setShowMnav(false);
                  setPage(4);
                }}
                to="/dashboard"
              >
                Payment
              </Link>
              <Link
                onClick={() => {
                  setShowMnav(false);
                  setPage(5);
                }}
                to="/dashboard"
              >
                Wishlist
              </Link>
              {currentUser ? (
                <Link
                  className="links2"
                  onClick={() => {
                    setUserData({});
                    signOutUser();
                    setShowMnav(false);
                  }}
                >
                  Logout
                </Link>
              ) : (
                <Link
                  className="links2"
                  onClick={() => {
                    setShowMnav(false);
                  }}
                  to="/signin"
                >
                  Login
                </Link>
              )}
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};
