import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/user.context";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../utils/firebase/firebase.utils";

function formatDateToCustomString(date) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const month = months[date?.getMonth()];
  const day = date?.getDate();
  const year = date?.getFullYear();

  const formattedDate = `${month} ${day}, ${year}`;
  return formattedDate;
}

function isDatePlusTenDaysGreaterOrEqual(inputDate) {
  // Get the current date
  const currentDate = new Date();

  let date =
    currentDate.setDate(currentDate.getDate()) -
    inputDate.setDate(inputDate.getDate() + 10);

  console.log(date);

  return;
}

const OrderList = () => {
  const { currentUser } = useContext(UserContext);
  const [order, setorder] = useState([]);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    const getOrders = async () => {
      const userRef = doc(db, "users", currentUser?.uid);

      const docSnap = await getDoc(userRef);

      const data = docSnap.data();

      if (data?.paymentReference) {
        setorder(data?.paymentReference);
      }
      setloading(false);
    };
    if (currentUser) {
      getOrders();
    }
  }, [currentUser]);

  if (loading) {
    return (
      <div className="address">
        <h1>Loading...</h1>
        <hr />
      </div>
    );
  }
  return order?.length === 0 ? (
    <div className="address">
      <h1>No orders yet</h1>
      <hr />
    </div>
  ) : (
    <div className="orderList">
      <div className="menu">
        <h1>OrderList</h1>
      </div>

      <div className="content-menu">
        <table>
          {order.map(
            ({
              cartItems,
              TotalAmount,
              cartCount,
              usersAddress,
              time,
              pReference,
            }) => {
              let timestamp = formatDateToCustomString(time?.toDate());
              let res = isDatePlusTenDaysGreaterOrEqual(time?.toDate());
              //  timestamp = time?.toDate();

              // timestamp = timestamp?.getDate();
              return (
                <tr style={{ cursor: "pointer" }}>
                  <td>
                    <div className="bg">
                      <p>Order ID #{pReference}</p>
                      <div className="flx">
                        <img
                          src={`http://u596h.erprev.com/res/img/items/${cartItems[0]?.ThumbImage}`}
                          alt=""
                        />

                        <div className="xy">
                          <p>
                            {cartItems[0].Name}{" "}
                            {cartCount - 1 > 1
                              ? `& ${cartCount - 1} items`
                              : cartCount - 1 > 0
                              ? `& ${cartCount - 1} item`
                              : ""}
                          </p>
                          <span>₦ {TotalAmount}</span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="name">{usersAddress?.displayName}</td>
                  <td className="dat">{timestamp || ""}</td>
                  <td className="des">
                    {usersAddress?.street}, {usersAddress?.state}.{" "}
                  </td>

                  <td className="dl">
                    <span>Paid</span>
                  </td>
                </tr>
              );
            }
          )}
        </table>
      </div>
    </div>
  );
};

export default OrderList;
