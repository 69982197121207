import React, { useEffect, useRef, useState } from "react";
import { Link, span, useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
  const Ref1 = useRef(null);
  const Ref2 = useRef(null);
  const Ref3 = useRef(null);
  const Ref4 = useRef(null);
  const Ref5 = useRef(null);
  const Ref6 = useRef(null);
  const Ref7 = useRef(null);
  const Ref8 = useRef(null);
  const Ref9 = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  return (
    <div className="termsAndCondition">
      {/**part 1 */}
      <div className="termsAndCondition__list">
        <h1 className="heading">Privacy Policy</h1>

        <p>Last updated: 29 February 2023</p>

        <div className="menu">
          <ul>
            <li>
              <span>Privacy Policy for Lash Interiors</span>{" "}
            </li>
            <li>
              <span
                onClick={() => {
                  Ref1.current?.scrollIntoView({ behavior: "smooth" });
                }}
              >
                Collection of Personal Information
              </span>{" "}
            </li>
            <li>
              <span
                onClick={() => {
                  Ref2.current?.scrollIntoView({ behavior: "smooth" });
                }}
              >
                Use of Personal Information
              </span>
            </li>
            <li>
              <span
                onClick={() => {
                  Ref3.current?.scrollIntoView({ behavior: "smooth" });
                }}
              >
                Disclosure of Personal Information
              </span>{" "}
            </li>
            <li>
              <span
                onClick={() => {
                  Ref4.current?.scrollIntoView({ behavior: "smooth" });
                }}
              >
                Security of Personal Information
              </span>
            </li>
            <li>
              <span
                onClick={() => {
                  Ref5.current?.scrollIntoView({ behavior: "smooth" });
                }}
              >
                Retention of Personal Information
              </span>
            </li>
            <li>
              <span
                onClick={() => {
                  Ref6.current?.scrollIntoView({ behavior: "smooth" });
                }}
              >
                Your Rights
              </span>
            </li>
            <li>
              {" "}
              <span
                onClick={() => {
                  Ref7.current?.scrollIntoView({ behavior: "smooth" });
                }}
              >
                Changes to this Privacy Policy
              </span>
            </li>
            <li>
              {" "}
              <span
                onClick={() =>
                  Ref8.current?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Contact Us
              </span>
            </li>

            <Link to="/termsofuse">
              <li>Terms of Use </li>
            </Link>
            <Link to="/refundpolicy">
              <li>
                <span>Refund Policy</span>{" "}
              </li>
            </Link>
          </ul>
        </div>
      </div>

      {/**part 2 */}
      <div className="termsAndCondition__content">
        <div className="content-child" id="1">
          <div className="mob_hd">
            <h1 className="heading">Privacy Policy</h1>

            <p>Last updated: 29 February 2023</p>
          </div>
          <h2 ref={Ref1}>Privacy Policy for Lash Interiors</h2>
          <p>
            At Lash Interiors, we are committed to protecting your privacy. This
            Privacy Policy explains how we collect, use, and disclose
            information when you visit our website or use our services. By using
            our website or services, you consent to the collection, use, and
            disclosure of your personal information as described in this Privacy
            Policy.
          </p>
        </div>

        <div className="content-child" id="2">
          <h2 ref={Ref2}>Collection of Personal Information</h2>
          <p>
            We may collect personal information from you when you visit our
            website or use our services. This may include your name, email
            address, phone number, address, and payment information. We may also
            collect information about your interactions with our website or
            services, including your browsing history and the pages you visit.
          </p>
        </div>

        <div className="content-child" id="3">
          <h2 ref={Ref3}>Use of Personal Information</h2>
          <p>
            We use your personal information to provide you with our products
            and services, to communicate with you about your orders and
            inquiries, and to improve our website and services. We may also use
            your personal information to send you marketing communications about
            our products and services.
          </p>
        </div>

        <div ref={Ref4} className="content-child" id="4">
          <h2>Disclosure of Personal Information</h2>
          <p>
            We may share your personal information with third-party service
            providers who perform services on our behalf, such as payment
            processing, shipping, and marketing. We may also share your personal
            information with our affiliates and business partners. We will only
            share your personal information for the purposes described in this
            Privacy Policy.
          </p>
        </div>

        <div className="content-child" id="5" ref={Ref5}>
          <h2>Security of Personal Information</h2>
          <p>
            We take reasonable measures to protect your personal information
            from unauthorized access, use, or disclosure. However, no security
            measures are 100% effective, and we cannot guarantee the security of
            your personal information.
          </p>
        </div>

        <div className="content-child" id="6" ref={Ref6}>
          <h2>Retention of Personal Information</h2>
          <p>
            We will retain your personal information for as long as necessary to
            provide you with our products and services, and as required by law.
          </p>
        </div>

        <div className="content-child" id="7" ref={Ref7}>
          <h2>Your Rights</h2>
          <p>
            You have the right to access and update your personal information,
            and to request that we delete your personal information. You may
            also opt-out of receiving marketing communications from us at any
            time. To exercise your rights, please contact us using the
            information provided below.
          </p>
        </div>
        <div className="content-child" id="8" ref={Ref8}>
          <h2>Changes to this Privacy Policy</h2>
          <p>
            We may update this Privacy Policy from time to time. If we make
            material changes to this Privacy Policy, we will notify you by email
            or by posting a notice on our website.
          </p>
        </div>
        <div className="content-child" id="9" ref={Ref9}>
          <h2>Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, You can contact
            us at lashinteriors2@gmail.com
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
