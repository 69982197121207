import Rug from "../../assets/shopPages/Rugs.png";
import React, { useContext, useEffect, useRef, useState } from "react";
import { BsArrowRight, BsChevronDown, BsFilter } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import ReactSlider from "react-slider";
import Product from "../../components/shop/Product";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
// prev and next
import Prev from "../../assets/shopPages/Prev.png";
import Next from "../../assets/shopPages/Next.png";
import { PorductContext } from "../../context/product.context";
import { ClipLoader } from "react-spinners";

import in1 from "../../assets/home/int_one.jpg";
import in2 from "../../assets/home/int_two.jpg";
import in3 from "../../assets/home/int_tr.jpg";
import in4 from "../../assets/home/int_four.jpg";
import { useNavigate } from "react-router-dom";

const insta_one = (i) => {
  let arr = [
    "https://www.instagram.com/p/Crv8HLgozUA/",
    "https://www.instagram.com/p/CrtgRQYoLjO/",
    "https://www.instagram.com/p/Cnl9Z8BD3HC/",
    "https://www.instagram.com/p/CrIsSuLojYe/",
  ];
  window.location.replace(arr[i]);
};

function getHexColor(colorStr) {
  var a = document.createElement("div");
  a.style.color = colorStr;
  var colors = window
    .getComputedStyle(document.body.appendChild(a))
    .color.match(/\d+/g)
    .map(function (a) {
      return parseInt(a, 10);
    });
  document.body.removeChild(a);
  return colors.length >= 3
    ? "#" +
        ((1 << 24) + (colors[0] << 16) + (colors[1] << 8) + colors[2])
          .toString(16)
          .substring(1)
    : false;
}

const Rugs = () => {
  const navigate = useNavigate();
  const { product, setProduct, numberOfProducts } = useContext(PorductContext);
  const [filteredData, setFilteredData] = useState(product);
  const [colors, setColors] = useState([]);
  useEffect(() => {
    axios
      .get("https://lash-backend.vercel.app/api/products")
      .then((res) => {
        // handle success
        const data = res.data?.filter((item) => {
          if (
            item?.Name !== "." &&
            item.Price !== "" &&
            item?.Name !== "" &&
            item?.Name !== " " &&
            item?.Class === "Chair"
          ) {
            return item;
          }
        });
        const colorsArr = [];
        if (data.length !== numberOfProducts) {
          setProduct(data);
          setFilteredData(data);
        }
        // color
        data?.forEach((item) => {
          let checkForDash = item.Name.split("").includes("-");
          let clr = getHexColor(
            item.Name?.split("-")[
              item.Name.split("-").length - 1
            ]?.toLowerCase()
          );

          if (!colorsArr.includes(clr)) colorsArr.push(clr);
          setColors([...colorsArr]);
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }, []);

  const [currentPage, setCurrentPage] = useState(1);

  let recordsPerPage = 3;
  let lastIndex = recordsPerPage * currentPage;
  let firstIndex = lastIndex - recordsPerPage;

  let records = filteredData.slice(firstIndex, lastIndex);
  let nPage = Math.ceil(filteredData.length / recordsPerPage);
  let numbers = [...Array(nPage + 1).keys()].slice(1);

  const res = useMediaQuery({ query: "(max-width: 800px)" });
  const [showFilter, setShowFilter] = useState(true);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changePage(n) {
    setCurrentPage(n);
  }
  function nextPage() {
    if (currentPage !== nPage) {
      setCurrentPage(currentPage + 1);
    }
  }

  useEffect(() => {
    if (res) {
      setShowFilter(false);
    } else {
      setShowFilter(true);
    }
  }, [res]);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, [currentPage, filteredData]);

  // for price filter
  const [maxPriceInFilter, setMaxPriceInFilter] = useState(0);
  useEffect(() => {
    // check max price
    let maxPrice = 0;

    product.forEach((item) => {
      if (Number(item?.Price?.split(",").join("")) > maxPrice) {
        maxPrice = Number(item?.Price?.split(",").join(""));
      }
    });

    setMaxPriceInFilter(maxPrice);
  }, []);

  // set category and filtered category
  //
  const [selectedCategory, setSelectedCategory] = useState("");
  const [filter, setFilter] = useState("");

  const handleColorsClick = (category) => {
    setFilteredData((prev) =>
      product.filter((item) => {
        let colorsObj = getHexColor(
          item.Name?.split("-")[item.Name.split("-").length - 1]?.toLowerCase()
        );
        if (colorsObj === undefined) {
          return false;
        } else {
          let colorsArray = Object.keys(colorsObj);

          if (category === colorsObj) {
            return true;
          } else {
            return false;
          }
        }
      })
    );

    setCurrentPage(1);

    setSelectedCategory(category);
    setFilter(category);
  };

  // change price value
  let minMaxArray = [0, maxPriceInFilter];
  const [minMax, setMinMax] = useState(minMaxArray);

  const handlePriceClick = (min, max) => {
    setFilteredData((prev) =>
      product.filter((item) => {
        return (
          Number(item?.Price?.split(",").join("")) >= min &&
          Number(item?.Price?.split(",").join("")) <= max
        );
      })
    );
    setCurrentPage(1);

    setSelectedCategory(`₦${min} - ₦${max}`);
  };

  const { setCurrentOpenProduct } = useContext(PorductContext);

  // set loader
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, [filteredData, currentPage]);

  return (
    <div className="shop">
      <section class="banner_section">
        <div class="large_image_container lng">
          <img src={Rug} alt="" />
        </div>

        <div class="text_container office">
          <h1>Rugs</h1>
          <div class="tag_line_text">
            <p>
              Transform your space with our stunning collection of rugs, crafted
              with the perfect blend of elegance and comfort. Choose from a wide
              range of designs to add a touch of sophistication and warmth to
              any room in your home.
            </p>
          </div>
        </div>
      </section>

      <section class="product_section" id="product_section">
      <div className="mobile_head">
        <div className="dt">
          <div className="head">
            <p>Popularity: Most Popular</p>
            <BsFilter />
          </div>
          <div
            onClick={() => setShowFilter(!showFilter)}
            className="filter_btn"
          >
            <p>FILTER</p>
          </div>
        </div>
        {!showFilter && <h1>Lash Products</h1>}
      </div>

      {showFilter && (
        <div className="filter">
          <h3>Filter</h3>
          <div className="tags">
            {filter && (
              <div className="tag">
                <p>{filter}</p>
                <FaTimes
                  style={{ cursor: "pointer" }}
                  color="#898989"
                  // reset
                  onClick={() => {
                    setFilteredData(product);
                    setFilter("");
                  }}
                />
              </div>
            )}
          </div>
          <div className="colors">
            <div className="head">
              <p>Colors</p>
              <BsChevronDown />
            </div>
            <div className="color-list">
              {colors.map((color, index) => {
                return (
                  <div
                    style={{ background: color, cursor: "pointer" }}
                    key={index}
                    onClick={() => handleColorsClick(color)}
                  ></div>
                );
              })}
            </div>
          </div>

          <div className="price-range">
            <div className="head">
              <p>Price Range</p>
              <BsChevronDown />
            </div>
            <ReactSlider
              className="slider"
              thumbClassName="thumb"
              trackClassName="track"
              max={maxPriceInFilter}
              defaultValue={[0, maxPriceInFilter]}
              ariaLabel={["Lower thumb", "Upper thumb"]}
              ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
              renderThumb={(props, state) => {
                minMaxArray = state.value;

                console.log(minMaxArray);
                return (
                  <div {...props}>
                    <p>₦{state.valueNow}</p>
                  </div>
                );
              }}
              pearling
              minDistance={maxPriceInFilter / 200}
            />
          </div>
          <div className="btns">
            <div
              className="apply"
              onClick={() => {
                handlePriceClick(minMaxArray[0], minMaxArray[1]);
              }}
            >
              <p>Apply Now</p>
            </div>
            <div
              className="reset"
              onClick={() => {
                setFilteredData(product);
              }}
            >
              <p>Reset</p>
            </div>
          </div>
        </div>
      )}

      <div className="products">
        <div className="head">
          <h1>Lash Products</h1>
          <div>
            <p>Popularity: Most Popular</p>
            <BsFilter />
          </div>
        </div>

        {loading ? (
          <div className="boxLoading">
            <ClipLoader size={100} color="green" />
          </div>
        ) : (
          <>
            <div className="product_grid">
              {records.map((x, i) => {
                if (x?.Class === "Chair") {
                  return (
                    <Product
                      key={i}
                      onClick={() => setCurrentOpenProduct(x)}
                      name={x?.Name}
                      slug={x?.Name}
                      category={x?.category}
                      image={x?.image}
                      price={x?.Price}
                      brand={x?.brand}
                      rating={x?.rating}
                      numReviews={x?.numReviews}
                      description={x?.Description}
                      Available_colors={x?.Available_colors}
                      chair_settings={x?.chair_settings}
                      product={x}
                    />
                  );
                }
              })}
            </div>

            <div className="pagination">
              <div className="page_item">
                <img
                  src={Prev}
                  alt=""
                  onClick={prePage}
                  style={{ cursor: "pointer" }}
                />

                <div className="nums">
                  {currentPage !== 1 && (
                    <span
                      onClick={() => changePage(1)}
                      className={currentPage === 1 ? "active" : ""}
                      style={{
                        cursor: "pointer",
                        display: `${res ? "none" : ""}`,
                      }}
                    >
                      1
                    </span>
                  )}

                  {numbers.length - currentPage <= currentPage - 1 && (
                    <span
                      style={{
                        display: `${res ? "none" : ""}`,
                      }}
                    >
                      .....
                    </span>
                  )}

                  {numbers
                    .slice(currentPage - 1, currentPage + 2)
                    .map((n, i) => {
                      if (n !== numbers.length) {
                        return (
                          <span
                            key={i}
                            onClick={() => changePage(n)}
                            className={currentPage === n ? "active" : ""}
                            style={{
                              cursor: "pointer",
                              display: `${res ? "none" : ""}`,
                            }}
                          >
                            {n}
                          </span>
                        );
                      }
                    })}

                  {numbers.length - currentPage >= currentPage - 1 && (
                    <span
                      style={{
                        display: `${res ? "none" : ""}`,
                      }}
                    >
                      .....
                    </span>
                  )}

                  <span
                    onClick={() => changePage(numbers.length)}
                    className={currentPage === numbers.length ? "active" : ""}
                    style={{
                      cursor: "pointer",
                      display: `${res ? "none" : ""}`,
                    }}
                  >
                    {numbers.length}
                  </span>
                </div>

                <img
                  src={Next}
                  alt=""
                  onClick={nextPage}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </section>

    <div className="interior_design">
      <div className="a">
        <h1>
          Lash <br /> Interiors Design
        </h1>
        <hr />
        <p>
          Let us bring your vision to life with custom-made furniture tailored
          to your exact specifications. From size to fabric and everything in
          between, we've got you covered.
        </p>

        <a href="https://www.instagram.com/lashinteriorsdesign/">
          contact us
        </a>
      </div>
      <div className="b" href="hi">
        <div
          className="single"
          style={{
            backgroundImage: `url(${in1})`,
            cursor: "pointer",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
          onClick={() => insta_one(0)}
        >
          {" "}
          <h3>
            Coastal <br />
            Comforts
          </h3>
          <span className="option">popular</span>
        </div>

        <div className="others">
          <div className="two">
            <div
              className="in"
              style={{
                backgroundImage: `url(${in2})`,
                cursor: "pointer",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
              onClick={() => insta_one(1)}
            >
              <h3>
                Rustic <br /> Retreats
              </h3>
            </div>
            <div
              className="in"
              style={{
                backgroundImage: `url(${in3})`,
                cursor: "pointer",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
              onClick={() => insta_one(2)}
            >
              <span className="option">New</span>
              <h3>
                Luxe <br /> Lounge
              </h3>
            </div>
          </div>
          <div
            className="one"
            style={{
              backgroundImage: `url(${in4})`,
              cursor: "pointer",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
            onClick={() => insta_one(3)}
          >
            <h3>Coastal Comforts</h3>
            <span className="option">New</span>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Rugs;
