import React, {useRef, useEffect} from "react";
import ab6 from "../../assets/blog/blogUser.png";
import img from "../../assets/blog/blogVintage.png"
import imgg from "../../assets/blog/blogVintage2.png"

const BlogPost2 = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);
  return (
    <div className="blog-content"  >
      <div className="home">
        <div className="content">
          <h1>
            Vintage Finds: How to Incorporate Antiques into Your Modern Home
          </h1>
          <p>
            {" "}
            This blog provides inspiration and advice on incorporating vintage
            and antique decor into modern home design.
          </p>

          <div className="intro">
            <img src={ab6} alt="" />

            <div className="data">
              <h6>Ayomide Olalekan</h6>
              <p>Tuesday, 18 May 2023</p>
            </div>
          </div>
        </div>

        <div className="img">
          <img src={img} alt="" />
        </div>
      </div>

      <div className="text">
        <p>
          Incorporating antiques into modern home decor has become increasingly
          popular in recent years. Vintage finds can add character and depth to
          any space, and they are a sustainable choice for those who want to
          reduce their environmental impact. However, mixing old and new can be
          a challenge. In this post, we will share tips and tricks for
          seamlessly incorporating antique pieces into your modern home.
        </p>
        <h2>1. Start Small</h2>
        <p>
          Start by incorporating small antique pieces into your home decor, such
          as decorative plates or vases. This will help you get a feel for how
          antique items can enhance your space without overwhelming it. These
          small touches can add character and interest to your home without
          clashing with your modern furnishings.
        </p>
        <h2>2. Mix and Match</h2>
        <p>
          The key to successful antique mixing is to find pieces that complement
          your existing decor. Look for pieces that share similar lines or
          colors to create a cohesive look. You can also mix and match different
          styles and eras, as long as you keep the overall look cohesive. For
          example, you might pair a mid-century modern sofa with an antique
          Persian rug.
        </p>
        <img src={imgg} alt="" />
        <span>
          Non massa enim vitae duis mattis. Vel in ultricies vel fringilla.
        </span>
        <h2>3. Make a Statement</h2>
        <p>
          If you want to make a bolder statement with your antique pieces,
          consider adding a large piece of furniture, like an antique armoire or
          a vintage dining table. This will instantly create a focal point in
          your space and add visual interest. However, be sure to balance the
          antique piece with modern accessories and furnishings to keep the
          overall look cohesive.
        </p>
        <h2>4. Incorporate Antiques in Unexpected Ways</h2>
        <p>
          Don't limit yourself to traditional antique items like furniture and
          decor. You can also incorporate vintage items in unexpected ways, such
          as using antique doors as a headboard or repurposing vintage suitcases
          as storage. Get creative with your antique finds to add interest and
          character to your home.
        </p>
        <h2>Versatility</h2>
        <p>
          Incorporating antique pieces into your modern home decor can be a fun
          and rewarding process. By following these tips and tricks, you can
          create a space that seamlessly blends old and new, and adds depth and
          character to your home.
        </p>
      </div>

   
    </div>
  );
};

export default BlogPost2;
