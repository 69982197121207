import React from "react";

const NoAddress = ({AddAddress}) => {
  return (
    <div className="address">
      <h1>No address added</h1>
      <hr />
      <a onClick={() => AddAddress()}>+ Add New Address</a>
    </div>
  );
};

export default NoAddress;
