import React, { useContext, useEffect, useRef, useState } from "react";
import "../styles/Product.scss";
import { FaStar, FaStarHalf } from "react-icons/fa";
import { MdOutlineAddShoppingCart } from "react-icons/md";
import { FiHeart } from "react-icons/fi";
import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";
import Review from "../components/product/Review";
import Suggestion from "../components/product/Suggestion";
import { useNavigate, useParams } from "react-router-dom";
import { CartContext } from "../context/cart.context";
import { PorductContext } from "../context/product.context";
import { UserDataContext } from "../context/userData.context";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../utils/firebase/firebase.utils";
import { UserContext } from "../context/user.context";
import { FakePrice } from "../components/shop/Product";

function getHexColor(colorStr) {
  if (colorStr.length < 2) return false;
  var a = document.createElement("div");
  a.style.color = colorStr;

  var colors = window
    .getComputedStyle(document.body.appendChild(a))
    .color.match(/\d+/g)
    .map(function (a) {
      return parseInt(a, 10);
    });
  document.body.removeChild(a);
  return colors.length >= 3
    ? "#" +
        ((1 << 24) + (colors[0] << 16) + (colors[1] << 8) + colors[2])
          .toString(16)
          .substring(1)
    : false;
}

function getObjectBySlug(arr, slug) {
  return arr?.find(
    (item) => item?.Name.toLowerCase("").split(" ").join("") === slug
  );
}

function getCartItem(arr, slug) {
  return arr.find(
    (item) => item.Name.toLowerCase("").split(" ").join("") === slug
  );
}

const Product = () => {
  // cart context
  const navigate = useNavigate();
  const { cartItems, addItemToCart, removeItemToCart } =
    useContext(CartContext);
  const [loading, setLoading] = useState(true);

  const { product, setProduct, currentOpenProduct, setCurrentOpenProduct } =
    useContext(PorductContext);
  const { slug } = useParams();
  const [images, setImages] = useState([]);

  const [sProduct, setSProduct] = useState(currentOpenProduct);
  const [colors, setColors] = useState([]);

  const [suggestedProduct, setSuggestedProducts] = useState([]);

  const { userData, currentUser, setUserData } = useContext(UserContext);

  const [unitsInStock, setunitsInStock] = useState(0);

  useEffect(() => {
    setLoading(true);
    const setloadFalse = () => setLoading(false);
    fetchDataI(setloadFalse);
    setLoading(false);
  }, [slug]);

  const [count, setCount] = useState(0);
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(null);

  const delay = 3500;
  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  const slideTo = (e) => {
    setIndex(e);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (cartItems.length > 0) {
      const res = getCartItem(cartItems, slug);

      if (res) {
        setCount(res?.quantity);
      } else {
        setCount(0);
      }
    } else {
      setCount(0);
    }

    return () => {};
  }, [slug]);

  // if it is not in context add it
  const [currentProduct, setCurrentProduct] = useState({});

  useEffect(() => {
    setLoading(true);

    if (slug !== undefined) {
      const loadFalse = () => setLoading(false);
      fetchDataE(loadFalse);
    }
  }, [slug]);

  const {
    wishlist,
    setWishlist,
    address,
    setAddress,
    alert,
    setAlert,
    slide,
    setslide,
  } = useContext(UserDataContext);

  const fetchDataI = async (setloadFalse) => {
    // Make the first API request
    const res = await axios.get("https://lash-api.vercel.app/api/stocks");
    const productsWithQuantity = res.data;

    // Make the second API request
    const res2 = await axios.get(
      "https://lash-backend.vercel.app/api/products"
    );
    const data2 = res2.data;

    const objectToBeAdded = getObjectBySlug(data2, slug);
    const rq = productsWithQuantity?.find(
      (itx) => itx?.Product === objectToBeAdded?.Name
    );

    console.log(rq);

    setunitsInStock(rq?.UnitsInStock);

    const result = { ...objectToBeAdded, UnitsInStock: rq?.UnitsInStock };

    setSProduct({ ...result });

    setImages([
      ...[
        `http://u596h.erprev.com/res/img/items/${result?.ThumbImage}`,
        `http://u596h.erprev.com/res/img/items/${result?.ThumbImage}`,
        `http://u596h.erprev.com/res/img/items/${result?.ThumbImage}`,
        `http://u596h.erprev.com/res/img/items/${result?.ThumbImage}`,
      ],
    ]);

    // Set colors
    let colorsArr = [];
    setColors([]);
    let suggestProductsArr = [];
    data2?.forEach((item) => {
      let checkIfSame =
        item.Name.split("-")
          .slice(0, item.Name.split("-").length - 1)
          .join("") ===
        result.Name.split("-")
          .slice(0, result.Name.split("-").length - 1)
          .join("");

      let clr = getHexColor(
        item.Name.split("-")[item.Name.split("-").length - 1].toLowerCase()
      );

      if (checkIfSame && !colorsArr.includes(clr?.color))
        colorsArr.push({ color: clr, name: item.Name });

      setColors([...colorsArr]);

      if (suggestProductsArr.length < 8 && result?.Name !== item?.Name)
        suggestProductsArr.push(item);

      setSuggestedProducts([...suggestProductsArr]);
    });
    setloadFalse();
  };

  const fetchDataE = async (loadFalse) => {
    // Make the first API request
    const res = await axios.get("https://lash-api.vercel.app/api/stocks");
    const productsWithQuantity = res.data;

    // Make the second API request
    const res2 = await axios.get(
      "https://lash-backend.vercel.app/api/products"
    );
    const data2 = res2.data;

    const objectToBeAdded = getObjectBySlug(data2, slug);
    const rq = productsWithQuantity?.find(
      (itx) => itx?.Product === objectToBeAdded?.Name
    );

    setunitsInStock(rq?.UnitsInStock);
    const result = { ...objectToBeAdded, UnitsInStock: rq?.UnitsInStock };

    setCurrentProduct(result);

    setCurrentOpenProduct(result);
    setSProduct({ ...result });

    setImages([
      ...[
        `http://u596h.erprev.com/res/img/items/${result?.ThumbImage}`,
        `http://u596h.erprev.com/res/img/items/${result?.ThumbImage}`,
        `http://u596h.erprev.com/res/img/items/${result?.ThumbImage}`,
        `http://u596h.erprev.com/res/img/items/${result?.ThumbImage}`,
      ],
    ]);

    // Set colors
    let colorsArr = [];
    setColors([]);
    let suggestProductsArr = [];
    data2?.forEach((item) => {
      let checkIfSame =
        item.Name.split("-")
          .slice(0, item.Name.split("-").length - 1)
          .join("") ===
        result.Name.split("-")
          .slice(0, result.Name.split("-").length - 1)
          .join("");

      let clr = getHexColor(
        item.Name.split("-")[item.Name.split("-").length - 1].toLowerCase()
      );

      if (checkIfSame && !colorsArr.includes(clr?.color))
        colorsArr.push({ color: clr, name: item.Name });

      setColors([...colorsArr]);

      if (suggestProductsArr.length < 8 && result?.Name !== item?.Name)
        suggestProductsArr.push(item);

      setSuggestedProducts([...suggestProductsArr]);
    });
    loadFalse();
  };

  return (
    <>
      {loading ? (
        <div className="min-h-[86vh] flex justify-center items-center">
          <ClipLoader size={100} color="green" />
        </div>
      ) : (
        <section className="product">
          <div className="head">
            <div className="left">
              <p id="inf">
                <span> </span>/{" "}
                {sProduct?.Category !== "N/A" ? sProduct?.Category : ""}
              </p>
              <div className="details">
                <h1>{sProduct?.Name}</h1>
                <div className="info">
                  <h3>
                    ₦ {sProduct?.Price} <br />
                    <span
                      style={{
                        textDecoration: "line-through",
                        fontSize: "70%",
                        color: "gray",
                      }}
                    >
                      ₦ {FakePrice(sProduct?.Price)}
                    </span>{" "}
                  </h3>
                  <div className="rating">
                    <span>
                      <FaStar color="gold" />
                      <FaStar color="gold" />
                      <FaStar color="gold" />
                      <FaStar color="gold" />
                      <FaStarHalf color="gold" />
                    </span>
                    <p>4.6 / 5.0 (556)</p>
                  </div>
                </div>
                <p id="text"></p>
              </div>
              <div className="colors">
                {colors.map(({ color, name }, id) => {
                  return (
                    <div
                      onClick={() => {
                        navigate(
                          `/product/${name.toLowerCase("").split(" ").join("")}`
                        );
                      }}
                      className={"with_border"}
                      key={name + id}
                      style={{ background: color }}
                    ></div>
                  );
                })}
              </div>
              <div className="btns">
                <div className="counter">
                  <span
                    className="not-selectable"
                    onClick={() => {
                      if (cartItems.length === 0) {
                        return count > 0 ? setCount(count - 1) : {};
                      }
                      const res = getCartItem(cartItems, slug);
                      if (res) {
                        removeItemToCart(sProduct);
                      }

                      return count > 0 ? setCount(count - 1) : {};
                    }}
                  >
                    -
                  </span>
                  <span>{count}</span>
                  <span
                    className="not-selectable"
                    onClick={() => {
                      addItemToCart(sProduct);
                      if (Number(unitsInStock) > count) {
                        setCount(count + 1);
                      }

                      if (Number(unitsInStock) == count) {
                        setAlert({
                          ...{
                            color: "yellow",
                            text: "Out of stock",
                          },
                        });
                        setslide(true);
                      }
                    }}
                  >
                    +
                  </span>
                </div>
                <div
                  className="add"
                  onClick={() => {
                    addItemToCart(sProduct);
                    if (Number(unitsInStock) > count) {
                      setCount(count + 1);
                    }

                    if (Number(unitsInStock) == count) {
                      setAlert({
                        ...{
                          color: "yellow",
                          text: "Out of stock",
                        },
                      });
                      setslide(true);
                    }
                  }}
                >
                  <p className="not-selectable">Add to cart</p>

                  <MdOutlineAddShoppingCart />
                </div>
              </div>
              <div
                className="wishlist not-selectable"
                onClick={() => {
                  let AlreadyWishlisted = false;
                  wishlist.forEach((item) => {
                    if (item?.Name === sProduct?.Name) AlreadyWishlisted = true;
                  });

                  if (AlreadyWishlisted) {
                    setAlert({
                      color: "yellow",
                      text: "Already added to wishlist",
                    });

                    setslide(true);
                  } else if (sProduct) {
                    let res = wishlist;

                    localStorage.setItem(
                      "wishlist",
                      JSON.stringify([sProduct, ...res])
                    );

                    const addAddress = async () => {
                      const userRef = doc(db, "users", currentUser?.uid);
                      try {
                        await updateDoc(userRef, {
                          wishlist: [sProduct, ...res],
                        });
                      } catch (error) {}
                    };

                    if (currentUser) {
                      addAddress();
                    }

                    setWishlist([sProduct, ...res]);

                    setAlert({
                      color: "green",
                      text: "New Product added to wishlist",
                    });

                    setslide(true);
                  }
                }}
              >
                <FiHeart size={24} />
                <p>Add to Wishlist</p>
              </div>
            </div>
            <div className="right">
              <div className="display">
                <div
                  className="slideshowSlider"
                  style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
                >
                  {images?.map((image, index) => (
                    <img className="slide" src={image} key={index} alt="" />
                  ))}
                </div>
              </div>
              <div className="images">
                {images?.map((image, id) => {
                  return (
                    <div
                      style={{
                        border: index === id ? "2px solid #84C029" : "",
                      }}
                      onClick={() => slideTo(id)}
                      key={id}
                    >
                      <img src={image} alt="" />
                    </div>
                  );
                })}
              </div>
              <div className="slideNav">
                <p className="not-selectable">
                  <span>{"0" + `${index + 1}`}</span>/{"0" + `${images.length}`}
                </p>
                <div>
                  <button
                    onClick={() =>
                      index > 0
                        ? setIndex(index - 1)
                        : setIndex(images.length - 1)
                    }
                  >
                    <AiOutlineLeft />
                  </button>
                  <button
                    onClick={() =>
                      index < images.length - 1
                        ? setIndex(index + 1)
                        : setIndex(0)
                    }
                  >
                    <AiOutlineRight />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="product-info">
           
          </div>
          <div className="suggestions">
            <div className="header">
              <p>Our suggestions</p>
              <h1>You may also like</h1>
            </div>
            <div className="suggested">
              {suggestedProduct.map((item, i) => {
                return <Suggestion product={item} />;
              })}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Product;
