import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

const RefundPolicy = () => {
  const Ref1 = useRef(null);
  const Ref2 = useRef(null);
  const Ref3 = useRef(null);
  const Ref4 = useRef(null);
  const Ref5 = useRef(null);
  const Ref6 = useRef(null);
  const Ref7 = useRef(null);
  const Ref8 = useRef(null);
  const Ref9 = useRef(null);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  return (
    <div className="termsAndCondition">
      {/**part 1 */}
      <div className="termsAndCondition__list">
        <h1 className="heading">Refund Policy</h1>

        <p>Last updated: 29 February 2023</p>

        <div className="menu">
          <ul>
            <li>
              <Link to="">Refund Policy for Lash Interiors</Link>{" "}
            </li>
            <li>
              <Link
                to=""
                onClick={() =>
                  Ref1.current?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Exchanges
              </Link>{" "}
            </li>
            <li>
              <Link
                to=""
                onClick={() =>
                  Ref2.current?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Damaged or Defective Items
              </Link>
            </li>
            <li>
              <Link
                to=""
                onClick={() =>
                  Ref3.current?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Cancellations
              </Link>{" "}
            </li>

            <li>
              {" "}
              <Link
                to=""
                onClick={() =>
                  Ref4.current?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Contact Us
              </Link>
            </li>
            <Link to="/privacypolicy">
              <li>Privacy Policy </li>
            </Link>
            <Link to="/termsofuse">
              <li>Terms of Use </li>
            </Link>
          </ul>
        </div>
      </div>

      {/**part 2 */}
      <div className="termsAndCondition__content">
        <div className="content-child" id="1">
          <div className="mob_hd">
            <h1 className="heading">Refund Policy</h1>

            <p>Last updated: 29 February 2023</p>
          </div>

          <h2 ref={Ref1}>Refund Policy for Lash Interiors</h2>
          <p>
            At Lash Interiors, we want you to be completely satisfied with your
            purchase. If for any reason you are not satisfied, we offer a
            hassle-free return policy. Please read the following guidelines
            before initiating a return: Returns must be made within 14 days of
            receipt or date of purchase, whichever is later. Returned items must
            be in their original condition and packaging. Shipping and handling
            charges are non-refundable. A restocking fee of 15% will be charged
            for all returned items. If the return is due to an error on our
            part, we will cover the cost of return shipping. All returns must be
            shipped with a tracking number and insurance to ensure safe
            delivery. Please contact our customer service team at [contact
            email] to initiate a return and receive a Return Authorization
            Number. Once your return is received and inspected, we will issue a
            refund in the original form of payment. Note: Some items are not
            eligible for return, including custom or made-to-order products.
            These items will be clearly marked as non-returnable on the product
            page.
          </p>
        </div>

        <div className="content-child" id="2">
          <h2 ref={Ref2}>Exchanges</h2>
          <p>
            If you wish to exchange an item for a different size, color, or
            product, please contact our customer service team to initiate an
            exchange. The same guidelines apply for exchanges as for returns.
          </p>
        </div>

        <div className="content-child" id="3">
          <h2 ref={Ref3}>Damaged or Defective Items</h2>
          <p>
            If you receive a damaged or defective item, please contact our
            customer service team within 48 hours of receipt to report the
            issue. We will provide instructions on how to proceed with the
            return or replacement process
          </p>
        </div>

        <div ref={Ref4} className="content-child" id="4">
          <h2>Cancellations</h2>
          <p>
            If you need to cancel an order, please contact our customer service
            team as soon as possible. If the order has not yet shipped, we will
            cancel the order and issue a full refund. If the order has already
            shipped, you will need to initiate a return once the item is
            received.
          </p>
        </div>

        <div className="content-child" id="5" ref={Ref5}>
          <h2>Contact Us</h2>
          <p>
            If you have any questions about this Refund Policy, You can contact
            us at lashinteriors2@gmail.com.
          </p>
        </div>
      </div>
    </div>
  );
};

export default RefundPolicy;
