import { createContext, useState, useEffect, useContext } from "react";
import data from "../data.js";
import { UserContext } from "./user.context.jsx";
import { db } from "../utils/firebase/firebase.utils.js";
import { doc, updateDoc } from "firebase/firestore";

export const UserDataContext = createContext({
  address: [],
  setAddress: () => {},
  wishlist: [],
  setWishlist: () => {},
  alert: {},
  setAlert: () => {},
  slide: false,
  setslide: () => {},
  page: null,
  setPage: () => {},
  usersSpecialRequest: "",
  setUsersSpecialRequest: () => {},
});

export const UserDataProvider = ({ children }) => {
  const [address, setAddress] = useState([]);
  const [wishlist, setWishlist] = useState([]);
  // for message alert
  const [alert, setAlert] = useState({});
  const [slide, setslide] = useState(false);
  const [page, setPage] = useState(1);
  const [currentlySelectedAddress, setCurrentlySelectedAddress] = useState({});
  const [usersSpecialRequest, setUsersSpecialRequest] = useState("");
  const { userData, setUserData, currentUser } = useContext(UserContext);

  const value = {
    address,
    setAddress,
    wishlist,
    setWishlist,
    alert,
    setAlert,
    slide,
    setslide,
    page,
    setPage,
    // for the currently selected address
    currentlySelectedAddress,
    setCurrentlySelectedAddress,
    usersSpecialRequest,
    setUsersSpecialRequest,
  };

  // address
  useEffect(() => {
    if (currentUser) {
      const res = userData?.addresses;
      
      if (res) {
        setAddress([...res]);
        setCurrentlySelectedAddress(res[0]);

        localStorage.setItem("address", JSON.stringify([...address]));
      } else {
        const res = JSON.parse(localStorage.getItem("address"));

        if (res) {
          const addAddress = async () => {
            const userRef = doc(db, "users", currentUser.uid);

            await updateDoc(userRef, {
              addresses: [...res],
            });
          };

          if (currentUser) {
            addAddress();
          }
        }
      }
    } else {
      const res = JSON.parse(localStorage.getItem("address"));
      
      if (res) {
        setAddress([...res]);
        setCurrentlySelectedAddress(res[0]);
      }
    }
  }, [userData]);

  // wishlist
  useEffect(() => {
    if (currentUser) {
      const res = userData?.wishlist;

      if (res) {
        setWishlist([...res]);

        localStorage.setItem("wishlist", JSON.stringify([...res]));
      } else {
        const res = JSON.parse(localStorage.getItem("wishlist"));
        if (res) {
          const addAddress = async () => {
            const userRef = doc(db, "users", currentUser.uid);

            await updateDoc(userRef, {
              wishlist: [...res],
            });
          };

          if (currentUser) {
            addAddress();
          }
        }
      }
    } else {
      const res = JSON.parse(localStorage.getItem("wishlist"));

      if (res) {
        setWishlist([...res]);
      } else {
      }
    }
  }, [userData]);

  return (
    <UserDataContext.Provider value={value}>
      {children}
    </UserDataContext.Provider>
  );
};
