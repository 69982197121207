import React, { useState, useEffect } from "react";
import img from "../assets/home/Mask.png";
import "react-phone-number-input/style.css";
import SignUpComponent from "../components/login/SignUp";
import SignInComponent from "../components/login/SignIn";
import VerificationModal from "../components/login/VerificationModal";
import EmailVerified from "../components/login/EmailVerified";
import CreateNewPassword from "../components/login/CreateNewPassword";

const SignUp = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  return (
    <div
      class="sign_up_form"
      style={{
        backgroundImage: `url(${img}) `,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <SignUpComponent />
    </div>
  );
};

export default SignUp;
