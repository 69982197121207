import React, { useContext, useEffect, useRef, useState } from "react";
import img1 from "../assets/home/Lounge Chairs.jpg";
import img2 from "../assets/home/Office Chairs.jpg";
import img3 from "../assets/home/Tables.jpg";
import img4 from "../assets/home/Accent Chairs.jpg";
import img5 from "../assets/home/DINING CHAIR.jpg";
import img6 from "../assets/home/Sofa.jpg";
import abt from "../assets/home/img.png";
import arr from "../assets/home/arrow-right.png";
import ig1 from "../assets/home/Images (1).png";
import otx from "../assets/home/otx.png";
import ar1 from "../assets/home/arl.png";
import ar2 from "../assets/home/arr.png";
import ar3 from "../assets/home/arrow right.png";
import ab5 from "../assets/blog/blog1.png";
import ab6 from "../assets/blog/blogUser.png";
import in1 from "../assets/home/int_one.jpg";
import in2 from "../assets/home/int_two.jpg";
import in3 from "../assets/home/int_tr.jpg";
import in4 from "../assets/home/int_four.jpg";
import gridImg1 from "../assets/home/gridImages/gridImg1.jpeg";
import gridImg2 from "../assets/home/gridImages/gridImg2.jpeg";
import gridImg3 from "../assets/home/gridImages/gridImg3.jpeg";
import gridImg4 from "../assets/home/gridImages/gridImg4.jpeg";
import gridImg5 from "../assets/home/gridImages/gridImg5.jpeg";
import gridImg6 from "../assets/home/gridImages/gridImg6.jpeg";
import gridImg7 from "../assets/home/gridImages/gridImg7.jpeg";
import gridImg8 from "../assets/home/gridImages/gridImg8.jpeg";
import { FakePrice } from "../components/shop/Product";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { PorductContext } from "../context/product.context";

// slideshow images
import slideProduct1 from "../assets/home/slideshowImages/Home chair image.jpg";
import slideProduct2 from "../assets/home/slideshowImages/TUFTED VELVET CHAIR-ASH.jpg";
import slideProduct3 from "../assets/home/slideshowImages/URBAN CHAIR-BLUE.jpg";

const colors = ["", "#0088FE", "#00C49F", "#FFBB28"];

const delay = 2500;
const insta_one = (i) => {
  let arr = [
    "https://www.instagram.com/p/Crv8HLgozUA/",
    "https://www.instagram.com/p/CrtgRQYoLjO/",
    "https://www.instagram.com/p/Cnl9Z8BD3HC/",
    "https://www.instagram.com/p/CrIsSuLojYe/",
  ];
  window.location.replace(arr[i]);
};

const Homepage = () => {
  const navigate = useNavigate();
  const [index, setIndex] = useState(1);
  const timeoutRef = useRef(null);
  const [images, setImages] = useState([]);
  const { product } = useContext(PorductContext);
  const res = useMediaQuery({ query: "(max-width: 800px)" });
  const [gridImageArray1, setGridImageArray1] = useState([gridImg1, gridImg2, gridImg3, gridImg4, gridImg5])
  const [gridImageArray2, setGridImageArray2] = useState([gridImg6, gridImg7, gridImg8])


  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === colors.length - 1 ? 1 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setImages([slideProduct1, slideProduct1, slideProduct2, slideProduct3]);
    return () => { };
  }, []);

  const [suggestedProducts, setSuggestedProducts] = useState([]);

  useEffect(() => {
    const arr = [];
    for (let i = 0; i <= 3; i++)
      arr.push(i === 3 ? product[i + 1] : product[i]);

    setSuggestedProducts([
      {
        SN: "177",
        ID: "177",
        Status: "1",
        Taxable: "1",
        Name: "NET OFFICE CHAIR-WHITE",
        Measure: "PCS",
        Currency: " &#x20A6;  ",
        Price: "55,000",
        ReOrderLevel: "2",
        Barcode: "P000177",
        Description: "",
        CategoryID: "1",
        ClassID: "1",
        Category: "N/A",
        Class: "N/A",
        ThumbImage: "NET-OF_536.jpg",
        IMAGE_: "",
      },
      {
        SN: "177",
        ID: "177",
        Status: "1",
        Taxable: "1",
        Name: "NET OFFICE CHAIR-WHITE",
        Measure: "PCS",
        Currency: " &#x20A6;  ",
        Price: "55,000",
        ReOrderLevel: "2",
        Barcode: "P000177",
        Description: "",
        CategoryID: "1",
        ClassID: "1",
        Category: "N/A",
        Class: "N/A",
        ThumbImage: "NET-OF_536.jpg",
        IMAGE_: "",
      },
      {
        SN: "42",
        ID: "42",
        Status: "1",
        Taxable: "1",
        Name: "TUFTED VELVET CHAIR-ASH",
        Measure: "PCS",
        Currency: " &#x20A6;  ",
        Price: "65,000",
        ReOrderLevel: "2",
        Barcode: "P000042",
        Description: "",
        CategoryID: "1",
        ClassID: "1",
        Category: "N/A",
        Class: "N/A",
        ThumbImage: "TUFTED_890.jpg",
        IMAGE_: "",
      },
      {
        SN: "72",
        ID: "72",
        Status: "1",
        Taxable: "1",
        Name: "URBAN CHAIR-BLUE",
        Measure: "PCS",
        Currency: " &#x20A6;  ",
        Price: "60,000",
        ReOrderLevel: "2",
        Barcode: "P000072",
        Description: "",
        CategoryID: "1",
        ClassID: "1",
        Category: "N/A",
        Class: "N/A",
        ThumbImage: "URBAN-_912.jpg",
        IMAGE_: "",
      },
    ]);
  }, [product?.length]);

  return (
    <div className="homepage">
      <div className="slideshow">
        <div className="fit">
          <h1> Make a Statement with Lash Interiors</h1>
          <p>
            Discover the perfect pieces for your spaces with Lash Interiors'
            curated selection of furniture and decor.
            <span>
              From cozy accents to statement pieces, explore all items now.
            </span>
          </p>

          <div className="btn" onClick={() => navigate("/shop")}>
            <button>EXPLORE ALL PRODUCTS</button>
            <div>
              <img src={arr} alt="" />
            </div>
          </div>
        </div>
        <div className="slideshowDots">
          <div className="dots">
            {colors.slice(1).map((_, idx) => (
              <div
                key={idx}
                className={`slideshowDot${index - 1 === idx ? " active" : ""}`}
              ></div>
            ))}
          </div>

          <div className="dir">
            <img
              src={ar1}
              alt=""
              onClick={() =>
                setIndex((prevIndex) =>
                  prevIndex === colors.length - 1 ? 1 : prevIndex + 1
                )
              }
            />
            <img
              src={ar2}
              alt=""
              onClick={() =>
                setIndex((prevIndex) =>
                  prevIndex === colors.length - 1 ? 1 : prevIndex + 1
                )
              }
            />
          </div>
        </div>

        <div
          className="slideshowSlider"
          style={{
            transform: `translate3d(calc(${-index * (res ? 113.5 : 70)}% + ${res ? 0 : 30
              }%), 0, 0)`,
          }}
        >
          {suggestedProducts.map((item, index) => {
            return (
              <div
                className="slide"
                key={index}
                style={{
                  backgroundImage: `url("${images[index]}")`,
                  backgroundPosition: "center",
                }}
              >
                <div
                  className="fit"
                  onClick={() =>
                    navigate(
                      `/product/${item?.Name?.toLowerCase("")
                        ?.split(" ")
                        ?.join("")}`
                    )
                  }
                >
                  <h1> {item?.Name}</h1>
                  <p>{item?.Category === "N/A" ? "" : item?.Category}</p>

                  <div className="btn">
                    <button>₦ {item?.Price}</button>

                    <div>
                      <img src={ar3} alt="" />
                    </div>
                  </div>
                  <div className="btn">
                    <button>
                      {" "}
                      <span
                        style={{
                          textDecoration: "line-through",
                          fontSize: "70%",
                          color: "gray",
                        }}
                      >
                        ₦ {FakePrice(item?.Price)}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="brs">
        <div className="cat">
          <h2>
            Browse through <br />
            our categories{" "}
          </h2>

          <hr />

          <p>
            See our catalog of a wide variety of <br /> high-quality furniture
            that is sure to <br /> suit your taste and style.
          </p>

          <div className="menu">
            <h2>Categories</h2>
            <div className="flx">
              {" "}
              <div>
                {" "}
                <li
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/OfficeChairs")}
                >
                  Office chairs
                </li>
                <li
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/DiningChairs")}
                >
                  {" "}
                  Dining chairs
                </li>
                <li
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/AccentChairs")}
                >
                  Accent chairs
                </li>
                <li
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/LoungeChairs")}
                >
                  Lounge Chairs
                </li>
              </div>
              <div>
                <li
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/Rugs")}
                >
                  Rugs{" "}
                </li>
                <li
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/Tables")}
                >
                  Tables
                </li>
                <li
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/HighStools")}
                >
                  High Stools
                </li>
                <li
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/Sofas")}
                >
                  Sofas
                </li>
              </div>
            </div>
          </div>
        </div>
        <div className="f2">
          <div className="imggrp1">
            <div
              onClick={() => navigate("/LoungeChairs")}
              style={{ cursor: "pointer" }}
            >
              <p className="q">lounge</p>
              <img src={img1} className="a" alt="" />
            </div>

            <div
              onClick={() => navigate("/OfficeChairs")}
              style={{ cursor: "pointer" }}
            >
              <p className="w">office</p>
              <img src={img2} className="b" alt="" />
            </div>

            <div
              onClick={() => navigate("/Tables")}
              style={{ cursor: "pointer" }}
            >
              <p className="e">table</p>
              <img src={img3} className="c" alt="" />
            </div>
          </div>
          <div className="imggrp2">
            <div
              onClick={() => navigate("/AccentChairs")}
              style={{ cursor: "pointer" }}
            >
              <p className="q">accent</p>
              <img src={img4} className="a" alt="" />
            </div>

            <div
              onClick={() => navigate("/DiningChairs")}
              style={{ cursor: "pointer" }}
            >
              <p className="w">dining</p>
              <img src={img5} className="b" alt="" />
            </div>

            <div
              onClick={() => navigate("/Sofas")}
              style={{ cursor: "pointer" }}
            >
              <p className="e">sofas</p>
              <img src={img6} className="c" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="whyus">
        <div className="title">
          <span>Why use us</span>

          <h1>
            Our Value <br /> Proposition
          </h1>

          <hr />
        </div>
        <div className="ot">
          <h3>Quality Products</h3>
          <p>
            Our furniture and decor is made with the highest-quality materials
            and designed to last.{" "}
          </p>
        </div>
        <div className="ot">
          <h3>Affordable Price</h3>
          <p>
            We know that designing your dream space can be expensive, which is
            why we offer our products at a fair and competitive price.{" "}
          </p>
        </div>
        <div className="ot">
          <h3>Customization</h3>
          <p>
            We offer customizable furniture options that can be tailored to your
            specific needs, ensuring that you get exactly what you want.
          </p>
        </div>
        <div className="ot">
          <h3>Fast and Reliable Shipping</h3>
          <p>
            We know you're excited to start enjoying your new furniture and
            decor, which is why we offer fast and reliable shipping.
          </p>
        </div>
        <div className="ot">
          <h3>Expert Advice</h3>
          <p>
            Our team of design experts is always here to help you make the right
            decisions for your space.
          </p>
        </div>
      </div>

      <div className="interior_design">
        <div className="a">
          <h1>
            Lash <br /> Interiors Design
          </h1>
          <hr />
          <p>
            Let us bring your vision to life with custom-made furniture tailored
            to your exact specifications. From size to fabric and everything in
            between, we've got you covered.
          </p>

          <a href="https://www.instagram.com/lashinteriorsdesign/">
            contact us
          </a>
        </div>
        <div className="b" href="hi">
          <div
            className="single"
            style={{
              backgroundImage: `url(${in1})`,
              cursor: "pointer",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
            onClick={() => insta_one(0)}
          >
            {" "}
            <h3>
              Coastal <br />
              Comforts
            </h3>
            <span className="option">popular</span>
          </div>

          <div className="others">
            <div className="two">
              <div
                className="in"
                style={{
                  backgroundImage: `url(${in2})`,
                  cursor: "pointer",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
                onClick={() => insta_one(1)}
              >
                <h3>
                  Rustic <br /> Retreats
                </h3>
              </div>
              <div
                className="in"
                style={{
                  backgroundImage: `url(${in3})`,
                  cursor: "pointer",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
                onClick={() => insta_one(2)}
              >
                <span className="option">New</span>
                <h3>
                  Luxe <br /> Lounge
                </h3>
              </div>
            </div>
            <div
              className="one"
              style={{
                backgroundImage: `url(${in4})`,
                cursor: "pointer",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
              onClick={() => insta_one(3)}
            >
              <h3>Coastal Comforts</h3>
              <span className="option">New</span>
            </div>
          </div>
        </div>
      </div>

      <div className="utd">
        <div className="one">
          <span>Our articles</span>
          <h1>
            Stay <br /> up-to-date
          </h1>
          <hr />
          <p>
            At Lash Interiors, we believe that staying informed is the key to
            making informed decisions about your home decor.
            <span>
              {" "}
              Our blog features a range of articles on topics like interior
              design trends, furniture styling tips, and DIY projects. Whether
              you're a seasoned pro or just starting out, our blog has something
              for everyone.{" "}
            </span>
          </p>
          <div className="btn">
            <button onClick={() => navigate("/blog")}>
              EXPLORE ALL ARTICLES
            </button>
            <div>
              <img src={arr} alt="" />
            </div>
          </div>
        </div>

        <div className="two">
          <div
            className="img"
            style={{ backgroundImage: `url(${abt})`, cursor: "pointer" }}
            onClick={() => navigate("/blogpost/2")}
          >
            <div className="xt">
              <span>Interior Designs</span>
              <h2>Creating a Zen Space: How to Design a Relaxing Home</h2>
              <p> This blog offers ideas and...</p>

              <div className="a-w">
                <img src={ab6} alt="" />

                <span className="ab">By Femi Falana</span>
                <span className="cnt">.</span>
                <span>Friday, 1 April 2023</span>
              </div>
            </div>
          </div>

          <div
            className="img-text"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/blogpost/4")}
          >
            <div className="img">
              {" "}
              <img src={ab5} alt="" />{" "}
            </div>
            <div className="text">
              <span>Tips and Tricks</span>
              <h2>
                Tips and Tricks for a <br /> Festive Home
              </h2>
              <p> This blog offers ideas and...</p>

              <div className="a-w">
                <img src={ab6} alt="" />

                <span className="ab">By Femi Falana</span>
                <span className="cnt">.</span>
                <span>Friday, 1 April 2023</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ig">
        <div className="dt">
          <p>Follow us on instagram</p>
          <a>@lashinteriorsng</a>
        </div>
        {/* <img src={ig1} alt="" /> */}
        <div className="product-grid">

          <div className="product-pic-grid">

            {
              gridImageArray1.map((girdImage, index) => (
                <div className="product-pic-grid-item" key={index}>
                  <div className="black-veil"></div>

                  <img src={`${girdImage}`} />
                </div>
              ))
            }
          </div>

          <div className="product-pic-grid">

            {
              gridImageArray2.map((girdImage, index) => (
                <div className="product-pic-grid-item" key={index}>
                  <div className="black-veil"></div>
                  <img src={`${girdImage}`} />
                </div>
              ))
            }
          </div>

        </div>


      </div>

      <div className="tst">
        <img src={otx} alt="" />
      </div>
    </div>
  );
};

export default Homepage;
