import React, {useEffect} from "react";
import img from "../assets/blog/blog.png";
import { useNavigate } from "react-router-dom";
import WhyUs from "../components/home/WhyUs";
import otx from "../assets/home/otx.png";
import video from "../assets/about/video_example.png";
import ab1 from "../assets/about/about1.png";
import ab2 from "../assets/about/about2.png";
import ab3 from "../assets/about/about3.png";
import ab4 from "../assets/about/about4.png";
import in1 from "../assets/home/int_one.jpg";
import in2 from "../assets/home/int_two.jpg";
import in3 from "../assets/home/int_tr.jpg";
import in4 from "../assets/home/int_four.jpg";


const insta_one = (i) => {
  let arr = [
    "https://www.instagram.com/p/Crv8HLgozUA/",
    "https://www.instagram.com/p/CrtgRQYoLjO/",
    "https://www.instagram.com/p/Cnl9Z8BD3HC/",
    "https://www.instagram.com/p/CrIsSuLojYe/",
  ];
  window.location.replace(arr[i]);
};


const About = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);
  
  return (
    <div className="about">
      <div className="home">
        <div className="content">
          <h1>About Us</h1>
          <p>
            {" "}
            Explore our values, design principles, and commitment to creating
            eco-friendly, long-lasting products for your home
          </p>
        </div>

        <div className="img">
          <img src={video} alt="" />
        </div>
      </div>

      <div className="pilan">
        <div className="title">
          <span>Our Philosophy</span>

          <h1>
            Elevating Home Decor with Style, Innovation, and Sustainability
          </h1>

          <hr />
        </div>

        <div className="content">
          <h1>Lash Interiors - Redefining the Furniture Shopping Experience</h1>
          <p>
            We are not your typical furniture company. We're on a mission to
            revolutionize the way people shop for furniture by offering unique,
            high-quality pieces that are both affordable and convenient. Our
            passion for design, craftsmanship, and sustainability is what sets
            us apart from the rest.
          </p>

          <div className="flex_content">
            <div className="a">
              <h1>5+</h1>
              <p>Years Experience</p>
            </div>
            <div className="a">
              <h1>5000+</h1>
              <p>Happy Clients</p>
            </div>
            <div className="a">
              <h1>30+</h1>
              <p>Projects Finished</p>
            </div>
          </div>
        </div>
      </div>

      <WhyUs />

      <div className="interior_design">
        <div className="a">
          <h1>
            Lash <br /> Interiors Design
          </h1>
          <hr />
          <p>
            Let us bring your vision to life with custom-made furniture tailored
            to your exact specifications. From size to fabric and everything in
            between, we've got you covered.
          </p>

          <a href="https://www.instagram.com/lashinteriorsdesign/">
            contact us
          </a>
        </div>
        <div className="b" href="hi">
          <div
            className="single"
            style={{
              backgroundImage: `url(${in1})`,
              cursor: "pointer",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
            onClick={() => insta_one(0)}
          >
            {" "}
            <h3>
              Coastal <br />
              Comforts
            </h3>
            <span className="option">popular</span>
          </div>

          <div className="others">
            <div className="two">
              <div
                className="in"
                style={{
                  backgroundImage: `url(${in2})`,
                  cursor: "pointer",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
                onClick={() => insta_one(1)}
              >
                <h3>
                  Rustic <br /> Retreats
                </h3>
              </div>
              <div
                className="in"
                style={{
                  backgroundImage: `url(${in3})`,
                  cursor: "pointer",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
                onClick={() => insta_one(2)}
              >
                <span className="option">New</span>
                <h3>
                  Luxe <br /> Lounge
                </h3>
              </div>
            </div>
            <div
              className="one"
              style={{
                backgroundImage: `url(${in4})`,
                cursor: "pointer",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
              onClick={() => insta_one(3)}
            >
              <h3>Coastal Comforts</h3>
              <span className="option">New</span>
            </div>
          </div>
        </div>
      </div>

      <div className="deals">
        <img src={otx} alt="" />
      </div>
    </div>
  );
};

export default About;
