import React, {useRef, useEffect} from "react";
import img from "../../assets/blog/blog.png";
import imgg from "../../assets/blog/BlogCreatingAZen.png";
import imggg from "../../assets/blog/BlogCreatingAZen2.png";
import ab6 from "../../assets/blog/blogUser.png";

const BlogPost1 = () => {

  
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  return (
    <div className="blog-content"  >
      <div className="home">
        <div className="content">
          <h1>Creating a Zen Space: How to Design a Relaxing Home</h1>
          <p>
            {" "}
            This blog provides tips on creating a peaceful and relaxing home
            environment, including ideas for incorporating natural elements and
            decluttering.
          </p>

          <div className="intro">
            <img src={ab6} alt="" />

            <div className="data">
              <h6>Ayomide Olalekan</h6>
              <p>Tuesday, 18 May 2023</p>
            </div>
          </div>
        </div>

        <div className="img">
          <img src={imgg} alt="" />
        </div>
      </div>

      <div className="text">
        <p>
          Your home should be a sanctuary where you can escape the stress of
          daily life and relax. But creating a space that's both calming and
          functional can be a challenge. One way to achieve this is by
          incorporating a Zen-inspired design in your home. In this blog post,
          we'll explore the principles of Zen design and provide tips on how to
          create a peaceful and relaxing home.
        </p>
        <h2>The Principles of Zen Design</h2>
        <p>
          Zen design is based on the principles of simplicity, harmony, and
          balance. It's all about creating a space that feels calm, peaceful,
          and uncluttered. Here are some key elements of Zen design to keep in
          mind: Minimalism: Keep things simple and uncluttered. Avoid
          unnecessary decorations or furnishings, and focus on functional pieces
          that serve a purpose. Natural materials: Use natural materials such as
          wood, stone, and bamboo to create a sense of warmth and earthiness.
          Neutral color palette: Stick to a neutral color palette of whites,
          grays, and earth tones to create a sense of serenity. Light and space:
          Let in plenty of natural light and create an open and airy feel by
          leaving plenty of space between furnishings.
        </p>
        <img src={imggg} alt="" />
        <span>Try to keep things simple</span>
        <h2>Tips for Creating a Zen Space</h2>
        <p>
          Now that you know the principles of Zen design, here are some tips for
          creating a relaxing and peaceful home: Declutter: Clear out any
          unnecessary items or furnishings to create a minimalist space that's
          easy to maintain. Create a focal point: Choose one or two statement
          pieces, such as a piece of art or a beautiful plant, to create a focal
          point in the room. Incorporate nature: Add natural elements such as
          plants or a small water feature to bring the outdoors inside and
          create a sense of calm. Use soft lighting: Avoid harsh overhead
          lighting and instead use soft, warm lighting to create a cozy and
          inviting atmosphere. Create a comfortable space: Invest in comfortable
          seating and add soft textiles such as cushions and throws to create a
          cozy and inviting space.
        </p>

        <p>
          By incorporating these tips and principles of Zen design into your
          home, you can create a space that's both calming and functional. A
          Zen-inspired home will help you feel more relaxed and at ease,
          allowing you to enjoy your surroundings and find peace in the midst of
          a busy world.
        </p>
      </div>

 
    </div>
  );
};

export default BlogPost1;
