import React, { useContext, useEffect, useState } from "react";
import { UserDataContext } from "../../../context/userData.context";
import { db } from "../../../utils/firebase/firebase.utils";
import { doc, updateDoc } from "firebase/firestore";
import { UserContext } from "../../../context/user.context";
import { CartContext } from "../../../context/cart.context";

const SavedAdderessComponent = ({ add, i, setRadio, radio, clickForward }) => {
  const {
    address,
    setAddress,
    currentlySelectedAddress,
    setCurrentlySelectedAddress,
  } = useContext(UserDataContext);

  const { userData, setUserData, currentUser } = useContext(UserContext);
  const { cartItems, cartCount, cartTotal, shippingCost, setShippingCost } =
    useContext(CartContext);

  useEffect(() => {
    if (currentlySelectedAddress?.isLagos) {
      if (currentlySelectedAddress?.isIsland === true && cartCount > 1) {
        setShippingCost(10000);
      } else if (
        currentlySelectedAddress?.isIsland === true &&
        cartCount === 1
      ) {
        setShippingCost(7000);
      } else if (
        currentlySelectedAddress?.isMainland === true &&
        cartCount === 1
      ) {
        setShippingCost(5000);
      } else if (
        currentlySelectedAddress?.isMainland === true &&
        cartCount > 1
      ) {
        setShippingCost(7000);
      }
    }
  }, [currentlySelectedAddress]);

  return (
    <div className="selectSave">
      <div className="c">
        <input
          type="radio"
          value={add.value}
          checked={radio === i ? true : false}
          onClick={() => {
            setRadio();
            setCurrentlySelectedAddress(address[i]);

            if (address[i].isLagos) {
              if (address[i].isIsland === true && cartCount > 1) {
                setShippingCost(10000);
              } else if (address[i].isIsland === true && cartCount === 1) {
                setShippingCost(7000);
              } else if (address[i].isMainland === true && cartCount === 1) {
                setShippingCost(5000);
              } else if (address[i].isMainland === true && cartCount > 1) {
                setShippingCost(7000);
              }
            }
          }}
        />
        <div className="ci">
          <h2>{add?.displayName}</h2>
          <p>
            {" "}
            {add?.street} , {add?.busStop}, {add?.state}{" "}
          </p>
          <div>
            <span className="cnt">Contact - </span>
            <span className="num"> {add?.phone}</span>
          </div>
        </div>
      </div>
      <div className="end">
        <span
          className="e"
          onClick={() => {
            setCurrentlySelectedAddress(address[i]);

            const res = address;

            res.splice(i, 1);
            localStorage.setItem("address", JSON.stringify(res));
            setAddress(JSON.parse(localStorage.getItem("address")));

            const addAddress = async () => {
              const userRef = doc(db, "users", currentUser.uid);

              await updateDoc(userRef, {
                addresses: [...res],
              });
            };

            if (currentUser) {
              addAddress();
            }

            clickForward();
          }}
        >
          EDIT{" "}
        </span>
        <span className="l">| </span>
        <span
          className="r"
          onClick={() => {
            const res = address;

            res.splice(i, 1);

            localStorage.setItem("address", JSON.stringify(res));

            setAddress(JSON.parse(localStorage.getItem("address")));

            const addAddress = async () => {
              const userRef = doc(db, "users", currentUser.uid);

              await updateDoc(userRef, {
                addresses: [...res],
              });
            };

            if (currentUser) {
              addAddress();
            }
          }}
        >
          Remove{" "}
        </span>
      </div>
    </div>
  );
};

export default SavedAdderessComponent;
