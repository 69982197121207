import React, { useContext, useEffect, useState } from "react";
import green from "../../assets/alert/circle-one.png";
import red from "../../assets/alert/circle-two.png";
import yellow from "../../assets/alert/circle-three.png";
import blue from "../../assets/alert/circle-four.png";
import close from "../../assets/alert/closeComponent.png";
import { UserDataContext } from "../../context/userData.context";
import { CartContext } from "../../context/cart.context";
import { useParams } from "react-router-dom";

const countObject = {
  addressCount: 1,
  cartCount: 1,
  wishlistCount: 1,
};

const Alert = () => {
  const { address, setAddress, alert, setAlert, slide, setslide } =
    useContext(UserDataContext);
  const { cartItems } = useContext(CartContext);
  const { color, text } = alert;

  const { slug } = useParams();

  useEffect(() => {
    setTimeout(() => {
      setslide(false);
    }, 2000);
  }, [alert]);

  return (
    slide && (
      <div
        className={"alert " + color}
        onLoad={() => {
          setTimeout(() => {
            setslide(false);
          }, 2500);
        }}
      >
        <img
          src={
            color === "green"
              ? green
              : color === "blue"
              ? blue
              : color === "yellow"
              ? yellow
              : red
          }
          alt=""
        />
        <p>{text}</p>
        <img
          src={close}
          alt=""
          onClick={() => setslide(false)}
          style={{ cursor: "pointer" }}
        />
      </div>
    )
  );
};

export default Alert;
