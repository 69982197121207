import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { CartProvider } from "./context/cart.context";
import { UserProvider } from "./context/user.context";
import { SearchProvider } from "./context/search.context";
import { PorductProvider } from "./context/product.context";
import { UserDataProvider } from "./context/userData.context";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      {" "}
      <UserProvider>
        <PorductProvider>
          <CartProvider>
            <SearchProvider>
              <UserDataProvider>
                <App />
              </UserDataProvider>
            </SearchProvider>
          </CartProvider>
        </PorductProvider>
      </UserProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
