import React, { useEffect } from "react";
import notFound from "../assets/notFound/404.png";
import text from "../assets/notFound/page not found (1).png";

const NotFoundPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);
  return (
    <div className="notFoundpage container">
      <img src={notFound} alt="" className="img" />
      <img src={text} alt="" className="text"/>
      
    </div>
  );
};

export default NotFoundPage;
