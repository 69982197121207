import React, { useState } from "react";

import up from "../../assets/faq/Vector.png";
import down from "../../assets/faq/Vector1.png";

const FaqQuestions = ({ header, text }) => {
  const [show, setShow] = useState(true);
  return (
    <div className="content">
      <div>
        <h1>{header}</h1>
        <div>
          {show ? (
            <img src={down} alt="down" onClick={() => setShow(!show)} />
          ) : (
            <img src={up} alt="up" onClick={() => setShow(!show)} />
          )}
        </div>
      </div>

      <p className={show? "":"active"}>{text}</p>
    </div>
  );
};

export default FaqQuestions;
