import React, { useContext } from "react";
import { CartContext } from "../../context/cart.context";

const CartItem = ({ cartItemDerails }) => {
  const { Name, Price, quantity, ThumbImage } = cartItemDerails;
  const { addItemToCart, removeItemToCart, clearItemFromCart } =
    useContext(CartContext);
  return (
    <div className="cart-item">
      <div className="l">
        <img
          src={`http://u596h.erprev.com/res/img/items/${ThumbImage}`}
          alt=""
        />
        <div className="info">
          <h3>{Name}</h3>
          <p id="min-price">₦ {Price}</p>
          <h4>
            <span>Color: </span>Lysed bright green
          </h4>
          <div className="bott">
            <div className="counter">
              <p onClick={() => removeItemToCart(cartItemDerails)}>-</p>
              <p>{quantity}</p>
              <p onClick={() => addItemToCart(cartItemDerails)}>+</p>
            </div>
            <div
              className="btn"
              onClick={() => clearItemFromCart(cartItemDerails)}
            >
              Remove
            </div>
          </div>
        </div>
      </div>
      <div className="price">₦ {Price}</div>
    </div>
  );
};

export default CartItem;
