import React, { useEffect } from "react";
import img from "../assets/faq/Rectangle 271.png";
import FaqQuestions from "../components/faq/Faq-questions";

const FAQ = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);
  return (
    <div className="faq">
      <div className="home">
        <div className="img">
          <img src={img} alt="" />
        </div>

        <div className="content">
          <h3>Help center</h3>
          <h1> Knowledge at your fingertips</h1>
          <p>
            {" "}
            Find solutions to common issues and learn more about our products
            and services
          </p>
        </div>
      </div>

      <div className="body">
        <div className="heading">
          <h1>Frequently Asked Questions</h1>
          <p>
            Find answers to commonly asked questions about our products,
            services, and policies. If you have a question that isn't answered
            here, feel free to contact us and we'll be happy to assist you.
          </p>
        </div>

        <div className="content-body">
          <FaqQuestions
            header="How long does it take to ship my order?"
            text="Orders are usually shipped within 2-3 business days within lagos and 2 -5 business days outside lagos after placing the
          order."
          />
          <FaqQuestions
            header="What payment methods do you accept?"
            text="We accept transfers and card payment."
          />
          <FaqQuestions header="What shipping options do you have?" text="" />
          <FaqQuestions
            header="Can I make changes to an existing order?"
            text="You can make changes to the order if it has not been shipped yet"
          />
          <FaqQuestions
            header="Can I customize the color or fabric of my furniture?"
            text=""
          />
        </div>
      </div>
    </div>
  );
};

export default FAQ;
