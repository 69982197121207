import React, { useContext, useEffect, useRef, useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { UserDataContext } from "../../context/userData.context";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../utils/firebase/firebase.utils";
import { UserContext } from "../../context/user.context";
import { CartContext } from "../../context/cart.context";

const defaultFormFields = {
  displayName: "",
  phone: "",
  email: "",
  street: "",
  busStop: "",
  state: "",
};

const checkIfLagos = (state) => {
  let rearangeState = state.toLowerCase("");
  let lagos = "lagos";

  if (rearangeState.includes(lagos)) {
    return true;
  } else {
    return false;
  }
};

const AddingAddress = ({ goBackToAddress }) => {
  const [value, setValue] = useState("");
  const [checkEmptyFeilds, setEmptyFeilds] = useState(true);

  const [formFields, setFormFields] = useState(defaultFormFields);
  const { displayName, phone, email, street, busStop, state } = formFields;

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const { userData, setUserData, currentUser } = useContext(UserContext);

  const { cartItems, cartCount, cartTotal, shippingCost, setShippingCost } =
    useContext(CartContext);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormFields({ ...formFields, [name]: value });
  };

  const [lagosRegion, setLagosRegion] = useState([false, false]);
  const [isLagos, setIsLagos] = useState(false);

  // save in local storage

  const {
    wishlist,
    setWishlist,
    address,
    setAddress,
    alert,
    setAlert,
    slide,
    setslide,
    currentlySelectedAddress,
    setCurrentlySelectedAddress,
  } = useContext(UserDataContext);

  useEffect(() => {
    const res = checkIfLagos(state);
    setIsLagos(res);
  }, [state]);

  return (
    <form action="#">
      <div class="es">
        <div class="eli">
          <label for="fullName" class="el">
            Full Name
          </label>
          <input
            type="text"
            class="ei"
            required
            onChange={handleChange}
            name="displayName"
            value={displayName}
          />
        </div>
      </div>

      <div class="ps">
        <div class="pc">
          <label for="phone" class="pl">
            Phone number
          </label>

          <div class="code_selector">
            <PhoneInput
              international
              defaultCountry="NG"
              value={value}
              onChange={setValue}
              name="phone"
            />
          </div>
        </div>
      </div>

      <div class="es">
        <div class="eli">
          <label for="email" class="el">
            Email address
          </label>
          <input
            type="text"
            class="ei"
            required
            name="email"
            onChange={handleChange}
            value={email}
          />
        </div>
      </div>

      <div class="es">
        <div class="eli">
          <label for="street" class="el">
            Street address
          </label>
          <input
            type="text"
            class="ei"
            required
            name="street"
            onChange={handleChange}
            value={street}
          />
        </div>
      </div>

      <div class="fls">
        <div class="fc">
          <label for="busstop" class="fl">
            Nearest Bus-Stop
          </label>
          <input
            type="text"
            class="firstName_input"
            required
            name="busStop"
            onChange={handleChange}
            value={busStop}
          />
        </div>

        <div class="lc">
          <label for="state" class="ll">
            State
          </label>
          <input
            type="text"
            class="lastName_input"
            required
            name="state"
            onChange={handleChange}
            value={state}
          />
        </div>
      </div>

      <div class="ts">
        <div class="tc">
          {isLagos && (
            <div class="terms_box">
              <div class="tc">
                <input
                  type="checkbox"
                  name="terms"
                  checked={lagosRegion[0]}
                  class="terms_check1"
                  onClick={() => {
                    setLagosRegion([true, false]);
                  }}
                />
              </div>

              <div class="tl">
                <label for="terms">Lagos Island</label>
              </div>

              <div class="tc">
                <input
                  type="checkbox"
                  name="terms"
                  class="terms_check1"
                  checked={lagosRegion[1]}
                  onClick={() => {
                    setLagosRegion([false, true]);
                  }}
                />
              </div>

              <div class="tl">
                <label for="terms">Lagos Mainland</label>
              </div>
            </div>
          )}
        </div>
      </div>

      <div class="sbs">
        <button
          type="button"
          class="sign_up"
          onClick={() => {
            if (isLagos) {
              // show prompt for island or mainland
              if (lagosRegion[0] === false && lagosRegion[1] === false) {
                setAlert({
                  color: "yellow",
                  text: "Kindly Select Island or Mainland",
                });
                setslide(true);
                return "";
              } else {
                // set shipping cost
                // islans
                if (lagosRegion[0] === true && cartCount > 1) {
                  setShippingCost(10000);
                } else if (lagosRegion[0] === true && cartCount === 1) {
                  setShippingCost(7000);
                } else if (lagosRegion[1] === true && cartCount === 1) {
                  setShippingCost(5000);
                } else if (lagosRegion[1] === true && cartCount > 1) {
                  setShippingCost(7000);
                }
              }
            } else {
              setShippingCost(0);
            }

            localStorage.setItem(
              "address",
              JSON.stringify([
                ...address,
                {
                  ...formFields,
                  phone: value,
                  isLagos,
                  isIsland: lagosRegion[0],
                  isMainland: lagosRegion[1],
                },
              ])
            );

            const addAddress = async () => {
              const userRef = doc(db, "users", currentUser.uid);

              await updateDoc(userRef, {
                addresses: [
                  {
                    ...formFields,
                    phone: value,
                    isLagos,
                    isIsland: lagosRegion[0],
                    isMainland: lagosRegion[1],
                  },
                  ...address,
                ],
              });
            };

            if (currentUser) {
              addAddress();
            }

            setAddress([
              {
                ...formFields,
                phone: value,
                isLagos,
                isIsland: lagosRegion[0],
                isMainland: lagosRegion[1],
              },
              ...address,
            ]);

            setCurrentlySelectedAddress({
              ...formFields,
              phone: value,
              isLagos,
              isIsland: lagosRegion[0],
              isMainland: lagosRegion[1],
            });

            setAlert({
              color: "green",
              text: "New address added",
            });
            setslide(true);

            goBackToAddress();
          }}
        >
          save address
        </button>
      </div>
    </form>
  );
};

export default AddingAddress;
