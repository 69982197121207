import React, { useContext, useEffect, useState } from "react";
import { CartContext } from "../../context/cart.context";
import CartItem from "./CartItem";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/user.context";
import { UserDataContext } from "../../context/userData.context";

function generateLink(productObject) {
  let itemToBeBought = "";
  productObject.forEach((item) => {
    itemToBeBought += item?.quantity;
    itemToBeBought += ", ";
    itemToBeBought += item?.Name;
    itemToBeBought += " ";
  });

  let number = "+2347033846692";
  let message = `Hi Lash Interiors, I would like to make an urgent order for: ${itemToBeBought}`;
  let url = "https://wa.me/";
  let end_url = `${url}${number}?text=${message}`;

  window.location.href = end_url;
}

const CartPageComponent = ({ clickForward }) => {
  const { cartItems, cartCount, cartTotal } = useContext(CartContext);
  const { usersSpecialRequest, setUsersSpecialRequest } =
    useContext(UserDataContext);
  const [specialRequest, setspecialRequest] = useState("");

  const { currentUser } = useContext(UserContext);

  const [currentDate, setCurrentDate] = useState(new Date());
  const [nextCurrentDate, setNextCurrentDate] = useState(new Date());

  const navigate = useNavigate();

  // Function to set the date four days ahead
  const setDateFourDaysAhead = () => {
    const newDate = new Date(currentDate);
    const nextNewDate = new Date(nextCurrentDate);
    newDate.setDate(newDate.getDate() + 2);
    nextNewDate.setDate(nextNewDate.getDate() + 4);
    setCurrentDate(newDate);
    setNextCurrentDate(nextNewDate);
  };

  useEffect(() => {
    setDateFourDaysAhead();
  }, []);

  return (
    <>
      {" "}
      <div className="left">
        <h1>
          Cart <span>{cartCount} items</span>
        </h1>
        <div className="cart-items">
          {cartItems?.map((x) => {
            return <CartItem cartItemDerails={x} key={x?.Name} />;
          })}
        </div>
        <div className="note">
          <h3>%</h3>
          <p>
            Note: For orders outside Lagos, shipping fees will not be included
            in the price of your product. Our dispatch driver will contact you
            to inform you of the logistics cost after liaising with the
            logistics service company. Once the cost has been confirmed, we at
            Lash Interiors will contact you to make the necessary payment
            arrangements separately. Thank you for your understanding.
          </p>
        </div>
        <div className="">
          <p className="text-gray text-[.875rem] mb-5 text-start">
            Do you have any special request?
          </p>
          <textarea
            className=" w-full  border-gray outline-0 "
            style={{
              height: "15rem",
              outline: "none",
              marginTop: "10px",
            }}
            onChange={(e) => setspecialRequest(e.target.value)}
            placeholder="Type your special requests here if you have one...."
          ></textarea>
        </div>
      </div>
      <div className="right">
        {cartItems.length > 0 ? (
          <div class="two">
            <div className="f">
              <h1>Order Summary</h1>
              <div className="main">
                <div className="op">
                  <span>Price</span>
                  <span>₦{cartTotal}</span>
                </div>

                <div className="op">
                  <span>Discount</span>
                  <span>₦0</span>
                </div>

                <div className="op">
                  <span>Shipping</span>
                </div>

                <hr />

                <div className="op dark">
                  <span>Total</span>
                  <span>₦ {cartTotal}</span>
                </div>

                <div className="op ">
                  <span>
                    Estimated Delivery{" "}
                    <span> {currentDate.toDateString()}</span> -
                    <span> {nextCurrentDate.toDateString()}</span>
                  </span>
                </div>

                <button
                  className="btn-sec"
                  onClick={() => {
                    if (currentUser) {
                      setUsersSpecialRequest(specialRequest);
                      clickForward();
                    } else {
                      navigate("/signin");
                    }
                  }}
                >
                  {currentUser ? "Proceed to checkout" : "Kindly Sign In"}
                </button>

                <button
                  style={{
                    backgroundColor: "red",
                  }}
                  onClick={() => {
                    if (currentUser) {
                      generateLink(cartItems);
                    } else {
                      navigate("/signin");
                    }
                  }}
                >
                  Urgent Order
                </button>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default CartPageComponent;
