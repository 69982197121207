import React, { useState, useEffect } from "react";

const CheckOut = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);
  return (
    <div class="checkout">


      <div class="one confirm">
        <div className="interior_design ">
          <div className="a">
            <span>Order Cancelled</span>
            <h1>
              We Have Received <br />
              Your Order
            </h1>
            <hr />
            <p>
              Your order is confirmed and being processed. We'll send you an
              email shortly with your order details and shipping information. If
              you have any questions about your order, please contact us at
              lashinteriors20@gmail.com.
            </p>
          </div>
        </div>
      </div>
      <div class="two">
        <div className="f">
          <h1>Order Summary</h1>
          <div className="main">
            <div className="op">
              <span>Price</span>
              <span>₦605,000</span>
            </div>

            <div className="op">
              <span>Discount</span>
              <span>₦0</span>
            </div>

            <div className="op">
              <span>Shipping</span>
              <span className="shp">Free</span>
            </div>

            <hr />

            <div className="op dark">
              <span>Total</span>
              <span>₦605,000</span>
            </div>

            <div className="op ">
              <span>
                Estimated Delivery by <span> 29 Mar 2023</span>
              </span>
            </div>

            <button className="btn-sec">continue to payment</button>
          </div>
        </div>
      </div>


    </div>
  );
};

export default CheckOut;
