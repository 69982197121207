import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const TermsAndCondition = () => {
  const Ref1 = useRef(null);
  const Ref2 = useRef(null);
  const Ref3 = useRef(null);
  const Ref4 = useRef(null);
  const Ref5 = useRef(null);
  const Ref6 = useRef(null);
  const Ref7 = useRef(null);
  const Ref8 = useRef(null);
  const Ref9 = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  return (
    <div className="termsAndCondition ">
      {/**part 1 */}
      <div className="termsAndCondition__list">
        <h1 className="heading">Terms of Use</h1>

        <p>Last updated: 29 February 2023</p>

        <div className="menu">
          <ul>
            <li>
              <Link
                to=""
                
              >
                Terms of Use for Lash Interiors
              </Link>{" "}
            </li>
            <li>
              <Link
                to=""
                onClick={() =>
                  Ref1.current?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Acceptance of Terms
              </Link>{" "}
            </li>
            <li>
              <Link
                to=""
                onClick={() =>
                  Ref2.current?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Use of Lash Interiors’ Services
              </Link>
            </li>
            <li>
              <Link
                to=""
                onClick={() =>
                  Ref3.current?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Intellectual Property
              </Link>{" "}
            </li>
            <li>
              <Link
                to=""
                onClick={() =>
                  Ref4.current?.scrollIntoView({ behavior: "smooth" })
                }
              >
                User Content
              </Link>
            </li>
            <li>
              <Link
                to=""
                onClick={() =>
                  Ref5.current?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Limitation of Liability
              </Link>
            </li>
            <li>
              <Link
                to=""
                onClick={() =>
                  Ref6.current?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Governing Law
              </Link>
            </li>
            <li>
              {" "}
              <Link
                to=""
                onClick={() =>
                  Ref7.current?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Contact Us
              </Link>
            </li>
            <Link to="/privacypolicy">
              <li>Privacy Policy </li>
            </Link>
            <Link to="/refundpolicy">
              <li>Refund Policy </li>
            </Link>
          </ul>
        </div>
      </div>

      {/**part 2 */}
      <div className="termsAndCondition__content">
        <div className="content-child" id="1">
          <div className="mob_hd">
            <h1 className="heading">Terms of Use</h1>

            <p>Last updated: 29 February 2023</p>
          </div>
          <h2 ref={Ref1}>Terms of Use for Lash Interiors</h2>
          <p>
            Welcome to Lash Interiors, a website that offers a range of products
            and services related to interior design and home decor. By using our
            website, you agree to comply with these terms of use, which govern
            your access to and use of Lash Interiors’ services, including our
            website, content, and any other products or services that we offer.
          </p>
        </div>

        <div className="content-child" id="2">
          <h2 ref={Ref2}>Acceptance of Terms</h2>
          <p>
            By accessing and using Lash Interiors’ services, you agree to these
            terms of use, which may be updated or modified from time to time
            without prior notice. It is your responsibility to review these
            terms periodically for any updates or changes.
          </p>
        </div>

        <div className="content-child" id="3">
          <h2 ref={Ref3}>Use of Lash Interiors’ Services</h2>
          <p>
            Lash Interiors’ services are intended for personal, non-commercial
            use only. You may not use our website or services for any illegal or
            unauthorized purpose, nor may you, in the use of our website,
            violate any laws in your jurisdiction.
          </p>
        </div>

        <div ref={Ref4} className="content-child" id="4">
          <h2>Intellectual Property</h2>
          <p>
            All content on Lash Interiors’ website, including but not limited to
            text, graphics, logos, images, videos, and software, is the property
            of Lash Interiors or its content suppliers and is protected by
            Nigeria and international copyright laws. You may not reproduce,
            distribute, or create derivative works from any content on our
            website without our prior written permission.
          </p>
        </div>

        <div className="content-child" id="5" ref={Ref5}>
          <h2>User Content</h2>
          <p>
            You may submit content to Lash Interiors, including comments,
            reviews, and suggestions. By submitting content to us, you grant
            Lash Interiors a non-exclusive, transferable, sub-licensable,
            royalty-free, worldwide license to use any content that you submit.
            You represent and warrant that you own or have the necessary rights
            to use and authorize Lash Interiors to use any content submitted by
            you.
          </p>
        </div>

        <div className="content-child" id="6" ref={Ref6}>
          <h2>Links to Third-Party Websites</h2>
          <p>
            Lash Interiors’ website may contain links to third-party websites or
            services that are not owned or controlled by Lash Interiors. We have
            no control over and assume no responsibility for the content,
            privacy policies, or practices of any third-party websites or
            services.
          </p>
        </div>

        <div className="content-child" id="7" ref={Ref7}>
          <h2>Limitation of Liability</h2>
          <p>
            Lash Interiors is not liable for any direct, indirect, incidental,
            special, or consequential damages arising from your use of our
            website or services, even if we have been advised of the possibility
            of such damages. You agree to indemnify and hold harmless Lash
            Interiors, its officers, directors, employees, agents, and
            affiliates from any claims, damages, expenses, or losses arising
            from your use of our website or services.
          </p>
        </div>
        <div className="content-child" id="8" ref={Ref8}>
          <h2>Governing Law</h2>
          <p>
            These terms of use shall be governed by and construed in accordance
            with the laws of the Country of Nigeria without regard to its
            conflict of law provisions.
          </p>
        </div>
        <div className="content-child" id="9" ref={Ref9}>
          <h2>Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, You can contact
            us at lashinteriors2@gmail.com
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndCondition;
