import React, { useContext } from "react";
import logo from "../assets/NewLogoFooter.png";
import ig from "../assets/ic-instagram.png";
import fb from "../assets/Nav Link.png";
import tw from "../assets/Nav Link (1).png";

import ot from "../assets/Nav Link (3).png";
import { Link } from "react-router-dom";
import { UserDataContext } from "../context/userData.context";

const Footer = () => {
  const { page, setPage } = useContext(UserDataContext);
  return (
    <>
      <footer class="footer">
        <a href="#" class="footer__logo">
          <img src={logo} alt="" srcset="" />
          <p>Making your home luxurious</p>
        </a>

        <div class="footer__social">
          <a href="https://www.instagram.com/lashinteriors/">
            <img src={ig} alt="" srcset="" />
          </a>
          <a href="https://www.facebook.com/p/Lash-Interiors-100072241135129/">
            <img src={fb} alt="" srcset="" />
          </a>
          <a href="https://twitter.com/lashinteriors">
            <img src={tw} alt="" srcset="" />
          </a>
          <a href="#">
            <img src={ot} alt="" srcset="" />
          </a>
        </div>

        <div class="footer__links col1">
          <div>
            <a href="" className="title">
              COMPANY
            </a>
            <Link to="/aboutus">About-Us</Link>
            <Link to="/contact">Contact</Link>
            <Link to="/blog">Blog</Link>
          </div>
        </div>

        <div class="footer__links col2">
          <div>
            <a href="" className="title">
              ACCOUNT
            </a>
            <Link to="/cart">Cart</Link>
            <Link to="/dashboard" onClick={() => setPage(5)}>Wishlist</Link>
            <Link to="/dashboard" onClick={() => setPage(3)}>Orders</Link>
          </div>
        </div>
        <div class="footer__links col3">
          <div>
            <a href="#" className="title">
              LEGAL
            </a>

            <Link to="/privacypolicy">Privacy Policy</Link>
            <Link to="/termsofuse">Terms of Use</Link>
            <Link to="/refundpolicy">Refund Policy</Link>
          </div>
        </div>

        <div class="footer__links col4">
          <div>
            <a  className="title">
              HELP AND SUPPORT
            </a>
            <Link to="/contact">Contact Us</Link>
            <Link to="/faq">FAQ's</Link>
          </div>
        </div>
      </footer>

      <div className="date">© 2023 Lash Interiors. All rights reserved.</div>
    </>
  );
};

export default Footer;
