import { useNavigate } from "react-router-dom";

const VerificationModal = ({ email, cfw }) => {
  const navigate = useNavigate();
  return (
    <div className="box">
      <h1>Check your mail</h1>
      <p>
        We sent a an email to to {email}. Kindly click on the link to change
        your password. If you cannot find the email check your{" "}
        <span className=" font-semibold">spam</span> folder.
      </p>

      <button
        class="sign_up"
        onClick={() => {
          cfw();
        }}
      >
        Sign In
      </button>
    </div>
  );
};

export default VerificationModal;
