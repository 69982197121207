import React, { useState,useEffect } from "react";

const CancelledOrder = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);
  return (
    <div class="checkout">
      <div class="one confirm">
        <div className="interior_design ">
          <div className="a">
            <span>Order Cancelled</span>
            <h1>
              Cancellation and Refund <br />
              Information
            </h1>
            <hr />
            <p className="pec">Dear Ayomide,</p>
            <p className="pec">
              We regret to inform you that your order has been cancelled as
              requested. We understand that things don't always go as planned,
              and we hope to serve you better in the future.
            </p>
            <p className="pec">
              Your refund is being processed and will be returned to your
              original payment method within 2 working days. Please note that
              the refund may take some time to reflect on your account,
              depending on your bank or credit card issuer.
            </p>
            <p className="pec">
              If you have any questions or concerns regarding your refund,
              please don't hesitate to contact our customer support team at our
              customer support email.
            </p>
            <p className="pec">
              Thank you for considering us for your purchase, and we look
              forward to serving you in the future.
            </p>
            <p className="pec">
              Best regards, <br /> Lash Interiors.
            </p>
          </div>
        </div>
      </div>
      <div class="two">
        <div className="f">
          <h1>Order Summary</h1>
          <div className="main">
            <div className="op">
              <span>Price</span>
              <span>₦605,000</span>
            </div>

            <div className="op">
              <span>Discount</span>
              <span>₦0</span>
            </div>

            <div className="op">
              <span>Shipping</span>
              <span className="shp">Free</span>
            </div>

            <hr />

            <div className="op dark">
              <span>Total</span>
              <span>₦605,000</span>
            </div>

            <div className="op ">
              <span>
                Estimated Delivery by <span> 29 Mar 2023</span>
              </span>
            </div>

            <div className="op dark">
              <span>Shipping Details</span>
            </div>

            <div className="op">
              <span>
                Ayomide Olalekan <br />
                Commercial Avenue, Saba Yaba, Lagos.
              </span>
            </div>

            <div className="op dark">
              <span>Payment Details</span>
            </div>

            <div className="op">
              <span>
                Ayomide Olalekan <br />
                •••• •••• •••• 3443
              </span>
            </div>

            <button>order cancelled</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelledOrder;
