import Footer from "./components/Footer";
import { Nav } from "./components/Nav";
import { Route, Routes } from "react-router-dom";
import TermsAndCondition from "./pages/TermsAndCondition";
import RefundPolicy from "./pages/RefundPolicy";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import NotFoundPage from "./pages/NotFoundPage";
import SignUp from "./pages/SignUp";
import "./styles.css";
import SignIn from "./pages/SignIn";
import FAQ from "./pages/FAQ";
import Contact from "./pages/Contact";
import Blog from "./pages/Blog";
import BlogPost from "./components/blog/BlogPost";
import BlogPost1 from "./components/blog/BlogPost1";
import BlogPost2 from "./components/blog/BlogPost2";
import Homepage from "./pages/Homepage";
import Shop from "./pages/Shop";
import SmallerNav from "./components/SmallerNav";
import About from "./pages/About";
import CheckOut from "./pages/CheckOut";
import UserDashboard from "./pages/UserDashboard";
import Product from "./pages/Product";
import CancelledOrder from "./pages/CancelledOrder";
import Cart from "./pages/Cart";
import OfficeChairs from "./pages/ShopPages/OfficeChair";
import DiningChairs from "./pages/ShopPages/DiningChairs";
import AccentChairs from "./pages/ShopPages/AccentChairs";
import Rugs from "./pages/ShopPages/Rugs";
import HighStools from "./pages/ShopPages/HighStools";
import LoungeChairs from "./pages/ShopPages/LoungeChairs";
import Sofas from "./pages/ShopPages/Sofas";
import Table from "./pages/ShopPages/Tables";
import BlogPost3 from "./components/blog/BlofPost3";
import Alert from "./components/alert/Alert";
import Search from "./components/search/Search";
import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "./utils/firebase/firebase.utils";

const siteVisit = async () => {
  const userDocRef = doc(db, "siteData", "visits");

  const userSnapshot = await getDoc(userDocRef);
  console.log(userSnapshot.data()?.visit);

  if (!userSnapshot.exists()) {
    try {
      await setDoc(userDocRef, {
        visit: 1,
      });
    } catch (error) {
      console.log("error creating the user", error.message);
    }
  } else {
    try {
      await setDoc(userDocRef, {
        visit: (userSnapshot.data()?.visit || 0) + 1,
      });
    } catch (error) {
      console.log("error", error.message);
    }
  }
};

function App() {
  const [width, setWidth] = useState(0);
  const [zoom, setZoom] = useState();

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWidth]);

  useEffect(() => {
    const zm = `${Math.floor((width / 1290) * 85.5)}%`;
    setZoom(zm);
  }, [width]);

  useEffect(() => {
    siteVisit();
  }, []);

  return (
    <div
      style={{
        zoom:
          width >= 1270 && width < 2200
            ? zoom
            : width >= 2200
            ? `${Math.floor((width / 2300) * 89)}%`
            : "",
      }}
    >
      <Nav />
      <Alert />
      <Search />
      <Routes>
        <Route index element={<Homepage />} />
        <Route path="/dashboard" element={<UserDashboard />} />
        <Route path="/AccentChairs" element={<AccentChairs />} />
        <Route path="/DiningChairs" element={<DiningChairs />} />
        <Route path="/HighStools" element={<HighStools />} />
        <Route path="/LoungeChairs" element={<LoungeChairs />} />
        <Route path="/OfficeChairs" element={<OfficeChairs />} />
        <Route path="/Rugs" element={<Rugs />} />
        <Route path="/Sofas" element={<Sofas />} />
        <Route path="/Tables" element={<Table />} />
        <Route path="/canceledorder" element={<CancelledOrder />} />
        <Route path="/product/:slug" element={<Product />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/checkout" element={<CheckOut />} />
        <Route path="/blogpost/1" element={<BlogPost />} />
        <Route path="/blogpost/2" element={<BlogPost1 />} />
        <Route path="/blogpost/3" element={<BlogPost2 />} />
        <Route path="/blogpost/4" element={<BlogPost3 />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/aboutus" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/termsofuse" element={<TermsAndCondition />} />
        <Route path="/refundpolicy" element={<RefundPolicy />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/*" element={<NotFoundPage />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
