import React, { useEffect, useRef } from "react";
import img from "../../assets/blog/blog.png";
import imgg from "../../assets/blog/blogBenefitsOfNaturalMaterial.png";
import ab6 from "../../assets/blog/blogUser.png";

const BlogPost = () => {
 


  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  return (
    <div className="blog-content" >
      <div className="home">
        <div className="content">
          <h1>
            The Benefits of Natural Materials in Home Decor: Why You Should
            Choose Wood, Stone, and Other Natural Materials for Your Home
          </h1>
          <p>
            {" "}
            Natural materials like wood, stone, and leather have been used in
            home decor for centuries, and for good reason.
          </p>

          <div className="intro">
            <img src={ab6} alt="" />

            <div className="data">
              <h6>Ayomide Olalekan</h6>
              <p>Tuesday, 18 May 2023</p>
            </div>
          </div>
        </div>

        <div className="img">
          <img src={img} alt="" />
        </div>
      </div>

      <div className="text">
        <p>
          The use of natural materials in home decor has become increasingly
          popular in recent years, and for good reason. Not only do natural
          materials bring a sense of warmth and texture to a space, but they
          also have a number of other benefits that make them a smart choice for
          any home. Here are just a few reasons why you should consider
          incorporating natural materials into your home decor:
        </p>
        <h2>Sustainability</h2>
        <p>
          One of the biggest advantages of natural materials is their
          sustainability. Unlike synthetic materials that are made from
          nonrenewable resources and can take hundreds of years to decompose,
          natural materials are biodegradable and can be easily recycled or
          composted. Using natural materials in your home decor can help reduce
          your carbon footprint and contribute to a more sustainable future.
        </p>
        <h2>Durability</h2>
        <p>
          Another advantage of natural materials is their durability. Many
          natural materials such as wood, stone, and leather are incredibly
          strong and long-lasting, and can withstand wear and tear much better
          than synthetic materials. Investing in high-quality natural materials
          for your home can save you money in the long run, as you won't have to
          replace them as often.
        </p>
        <img src={imgg} alt="" />
        <span>Achieving home decor with natural materials</span>
        <h2>Health Benefits</h2>
        <p>
          Natural materials are also known to have numerous health benefits. For
          example, wood has been shown to have a calming effect on the body and
          can help reduce stress and anxiety. Natural materials such as wool and
          linen are also hypoallergenic and can help prevent allergies and
          respiratory problems. By choosing natural materials for your home
          decor, you can create a healthier and more relaxing living space.
        </p>
        <h2>Aesthetic Appeal</h2>
        <p>
          Of course, one of the most obvious benefits of natural materials is
          their aesthetic appeal. Natural materials such as wood, stone, and
          leather have a timeless beauty that can add warmth, texture, and
          character to any room. Whether you're going for a rustic, minimalist,
          or modern look, natural materials can help you achieve the style
          you're after.
        </p>
        <h2>Versatility</h2>
        <p>
          Finally, natural materials are incredibly versatile and can be used in
          a variety of ways in your home decor. From hardwood floors and stone
          countertops to leather sofas and linen curtains, natural materials can
          be incorporated into any aspect of your living space. Whether you want
          to go all out with a natural-themed room or just add a few touches of
          wood or stone here and there, there are endless ways to incorporate
          natural materials into your home decor.
        </p>

        <p>
          In conclusion, there are numerous benefits to using natural materials
          in your home decor. From their sustainability and durability to their
          health benefits and aesthetic appeal, natural materials can enhance
          your living space in countless ways. So next time you're considering a
          home decor project, consider choosing wood, stone, or other natural
          materials to create a beautiful, sustainable, and healthy living
          environment.
        </p>
      </div>
    </div>
  );
};

export default BlogPost;
